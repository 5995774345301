/*--------------------------------------------------------------
HOME > MODULE HOME  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Forms
- Tabs
- Proprio
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#module_home{
	position: relative;
	&::before, &::after{
		content: '';
		@include abs(0, top);
		top: $tabsH;
		background: #fff;
		transition: .4s $quint;
		transform-origin: 0 0;
		animation: scaleX .5s .5s backwards;
	}
	&::before{
		box-shadow: 0 2px 9px rgba(#000, .2);
		z-index: -1;
	}
	&::after{
		height: 10px;
		z-index: 1;
	}
}



/*--------------------------------------------------------------
-	Forms
--------------------------------------------------------------*/
#module_home{
	form{
		display: none;
		&.on{
			display: block;
		}
		&.first{
			animation: fadeIn .3s .8s backwards;
		}
	}
}



/*--------------------------------------------------------------
-	Tabs
--------------------------------------------------------------*/
#module_home .tabs{
	display: flex;
	user-select: none;
	button{
		position: relative;
		flex: 1 1;
		margin-right: 4px;
		transition: none;
		&:last-child{
			margin: 0;
		}
		&.on{
			color: $scamac;
			&::after{
				transform: none;
			}
		}
		&::before, &::after{
			content: '';
			@include abs;
			z-index: -1;
			transform-origin: 0 100%;
			animation: scaleY .3s .6s backwards;
		}
		&::before{
			background: $scamac;
			box-shadow: 0 2px 9px rgba(#000, .2);
		}
		&::after{
			background: #fff;
			transition: .2s;
			transform: scaleY(0);
		}
		@for $i from 2 through 3 {
			&:nth-child(#{$i}){
				&::before{
					animation-delay: ($i / 10) + .5s;
				}
			}
		}
	}
	span{
		line-height: $tabsH;
		font-size: 1.3em;
		font-weight: bold;
		text-align: center;
		transition: .2s;
		animation: textFadeIn .3s .9s backwards;
		@include mobileL{
			font-size: 1em;
		}
	}
}



/*--------------------------------------------------------------
-	Propriétaire
--------------------------------------------------------------*/
#proprio_form{
	text-align: center;
	a, strong{
		font-weight: bold;
		color: $orange;
	}
	p{
		font-size: 1.1em;
		max-width: 340px;
		margin: 0 auto 20px;
		line-height: 1.3;
	}
	header{
		p{
			max-width: 300px;
			font-size: 1.3em;
		}
		strong{
			text-transform: uppercase;
		}
	}
	input{
		display: block;
		height: 40px;
		margin-bottom: 20px;
		padding: 0 15px;
		font-size: 15px;
		border: 1px solid $bg;
		background: #f7f8fa;
		box-sizing: border-box;
		&[type=text], &[type=tel]{
			float: left;
			width: calc(50% - 10px);
		}
		&[type=text]{
			margin-right: 20px;
		}
		&[type=email]{
			padding-left: 50px;
			width: 100%;
		}
	}
	button{
		position: relative;
		margin: 0 auto;
		padding: 0;
		height: 50px;
		width: 150px;
		font-size: 14px;
		letter-spacing: .1em;
		text-transform: uppercase;
		@include button($orange, true, true);
		&:hover{
			background: darken($orange, 10);
		}
		&::after{
			content: '';
			@include abs(1);
			width: 20px;
			height: 20px;
			border: 2px solid #fff;
			border-top-color: transparent;
			border-radius: 50%;
			opacity: 0;
			transition: .2s;
			animation: spin 1s linear infinite;
		}
		&:disabled{
			background: $texte;
			color: transparent;
			cursor: default;
			&::after{
				opacity: 1;
			}
		}
	}
	.email{
		display: block;
		position: relative;
		margin: 0;
		padding: 0;
		border: none;
		clear: both;
		svg{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 10px;
			margin: auto;
			vertical-align: middle;
			stroke: $trait;
		}
	}
}
