.aside{
    grid-area: as;
    margin-top: 0;
    @include laptop{
        margin-top: 50px;
    }
    .recommandation{
        margin-bottom: $gap;
        a {
            p {

            }
    		&:hover {
    			color: $texte;
    		}
        }
    }
    .featured_lot{
        padding-bottom: $gap;
        @include mobileL{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .h2like{
        margin: 0;
		padding: 20px 0;
        background-color: #fff;
        font-size: .8em;
    }
    .contact, .chat{
        margin-top: $gap;
    }
}

#conseils{
    .recommandation{
        transform: translateY(100px);
        opacity: 0;
        transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
        &.on {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    .featured_lot{
        transform: translateY(100px);
        opacity: 0;
        transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
        &.on {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    .contact, .chat{
        transform: translateY(100px);
        opacity: 0;
        transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
        &.on {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}
