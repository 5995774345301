/*--------------------------------------------------------------
FOOTER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Menu
- Social
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
footer{
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	max-width: $layoutW;
	margin: auto;
	padding: 0 $gap;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	z-index: 1;
	@include laptop{
		flex-direction: column;
		align-items: stretch;
		padding: 0;
	}
	&::before, &::after{
		content: '';
		position: absolute;
		top: 0;
		left: calc((-100vw + 100%) / 2 + 10px);
		bottom: 0;
		right: 0;
		background: lighten($scamac, 10);
		box-shadow: -20px 0 0 lighten($scamac, 10);
		z-index: -2;
	}
	&::after{
		left: calc(100% - #{$gap});
		right: calc((-100vw + 100%) / 2 + 10px);
		background: $scamac;
		box-shadow: 20px 0 0 $scamac;
		z-index: -1;
		@include laptop{
			display: none;
		}
	}
}



/*--------------------------------------------------------------
-	Menu
--------------------------------------------------------------*/
#footer_menu{
	margin-right: auto; // IE
	@include laptop{
		margin: 15px 0;
		text-align: center;
		li{
			display: block;
			&::after{
				display: none;
			}
			a{
				display: block;
				padding: 10px;
			}
		}
	}
}



/*--------------------------------------------------------------
-	Social
--------------------------------------------------------------*/
footer .social{
	display: flex;
	padding: 25px 0;
	justify-content: space-between;
	@include cols(3);
	background: $scamac;
	@include laptop{
		width: auto;
		max-width: none;
		flex-direction: column;
		align-items: center;
	}
	p{
		margin-left: 20px;
		@include laptop{
			margin: 0 0 5px;
			font-weight: normal;
		}
	}
	li{
		display: inline-block;
		margin: 0 10px;
		&:last-child{
			margin-right: 0;
		}
		@include laptop{
			margin: 10px 30px;
			&:last-child{
				margin: 10px 30px;
			}
		}
	}
	svg{
		fill: currentColor;
	}
}
