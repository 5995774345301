$lightgrey: #f2f2f2;
// Header
#onrecrute {
	padding-bottom: 0;
	.background {
		filter: brightness(0.65);
		position: absolute;
		top: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 485px;
		background: url(/img/recrutement/header.jpg) no-repeat top center;
		background-size: cover;
		z-index: -1;
		animation: slideIn 0.38s 0.07s backwards;
	}

	.rc-title {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: white;
		font-weight: 500;
		font-family: kelson;
		margin: 145px 0px;
		animation: textFadeIn 0.4s 0.409s backwards;

		@include laptop {
			margin: 100px 0px;
		}

		@include tablet {
			margin: 70px 0px;
		}

		h2 {
			margin-bottom: 20px;
			font-size: 2.9em;

			@include mobileL {
				font-size: 2.45em;
			}
		}

		p {
			font-size: 1.2em;
			@include mobileL {
				font-size: 1em;
			}
		}
	}
}

#onrecrute {
	.rc-header-offres {
		position: relative;
		display: flex;
		margin-left: 20px;
		margin-bottom: 170px;

		@include laptop {
			margin-left: 0;
		}

		@include tablet {
			margin-bottom: 155px;
		}

		@include mobileL {
            text-align: justify;
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            margin-bottom: 87px;
            margin-top: -44px;
		}

		.left-content {
			width: 500px;
			transform: translateY(50px);
			z-index: 2;
			box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
			padding: 60px 64px;
			background-color: white;
			animation: slideIn 0.4s 0.409s backwards;

			@include laptop {
				padding: 50px 54px;
				width: 450px;
			}

			@include tablet {
				width: 400px;
			}

			@include mobileL {
				padding: 30px 15px;
				padding-top: 10px;
                width: 100%;
                animation: fadeIn 0.4s 0.409s backwards;
			}

			p {
				font-weight: bold;
				font-size: 14px;
				line-height: 28px;
				margin-top: 20px;

				@include mobileL {
					font-size: 13px;
					line-height: 24px;
				}
			}

			a {
				font-family: kelson;
				margin-top: 40px;
				display: inline-block;
				font-size: 21px;
				text-align: center;
				color: $orange;
				border: 2px solid $orange;
				border-radius: 2px;
				padding: 20px 24px;
				transition: all ease-in-out 0.3s;
				box-sizing: border-box;

				@include mobileL {
					font-size: 18px;
					line-height: 26px;
					padding: 13px 12px;
					width: 100%;
				}

				&:hover {
					color: white;
					background-color: $orange;
				}
			}
		}

		.img {
			position: absolute;
			right: 0;
			z-index: -1;
			width: 600px;
			height: 685px;
			background: url(/img/recrutement/intro.jpg) no-repeat top center;
			background-size: cover;
			animation: slideIn 0.4s 0.509s backwards;

			@include laptop {
				right: -50px;
            }
            
            @include mobileL {
                display: none;
            }
		}
	}
}

// Nos offres
#onrecrute {
	.rc-offres {
		padding: 90px 9vw;
		@include tablet {
			padding: 12vw 0px;
		}

		@include mobileL {
			padding: 0px 0px;
		}

		h3 {
			font-size: 2em;
			color: $orange;
			font-family: kelson;
			margin: 30px 0px;
		}
		.alone {
			margin-top: 30px;
		}

		h4 {
			position: relative;
			text-transform: uppercase;
			font-weight: bold;
			background-color: $lightgrey;
			font-size: 14px;
			padding: 20px 15px;
			cursor: pointer;
			transition: ease-in-out 0.2s all;
			padding-right: 30px;

			@include tablet {
				padding-right: 40px;
			}

			@include mobileL {
				padding-right: 50px;
			}

			&:after {
				position: absolute;
				content: '';
				right: 30px;
				top: 47%;
				border: solid black;
				border-width: 0 2px 2px 0;
				display: inline-block;
				padding: 3px;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				transition: ease-in-out 0.2s all;

				@include tablet {
					right: 20px;
				}
			}

			&:hover {
				background: $orange;
				color: white;
			}
			&:hover:after {
				border: solid white;
				border-width: 0 2px 2px 0;
			}
		}

		.title-sm {
			text-transform: uppercase;
			color: $orange;
			font-weight: bold;
			margin-top: 20px;
			font-size: 16px;
			margin-bottom: -10px;
			display: block;
		}

		.container-sm {
			margin-left: 27px;
			margin-top: 35px;
			padding-right: 20px;
			max-height: 0px;
			overflow: hidden;

			@include mobileL {
				margin-top: 30px;
			}

			p {
				font-size: 14px;
				line-height: 28px;

				@include mobileL {
					font-size: 13px;
					line-height: 26px;
				}
			}

			a {
				display: inline-block;
				font-weight: bold;
				text-transform: uppercase;
				background-color: $lightgrey;
				font-size: 14px;
				padding: 18px;
				margin-top: 35px;
				margin-bottom: 35px;
				cursor: pointer;
			}
		}
	}

	strong {
		font-weight: bold !important;
	}
}

#onrecrute {
	.rc-form {
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		padding: 70px 0px;
		padding-bottom: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: $lightgrey;

		@include tablet {
			padding: 60px 0px;
			padding-bottom: 60px;
		}

		@include mobileL {
			padding: 40px 0px;
			padding-bottom: 40px;
		}

		h3 {
			color: black;
			font-size: 42px;
			font-family: kelson;

			@include tablet {
				font-size: 30px;
				width: 100%;
				text-align: center;
			}
		}

		button {
			margin-top: 30px;
			background: $orange;
			padding: 20px;
			color: white;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 19px;
			letter-spacing: 1px;
			font-family: kelson;
			border-radius: 3px;
			transition: background-color 0.3s ease-in-out;

			@include tablet {
				font-size: 17.5px;
				width: 70%;
				margin: 0 auto;
				margin-top: 20px;
				line-height: 26px;
				padding: 15px 17px;
			}

			&:hover {
				background-color: #bb5c00;
			}
		}
	}

	.rc-form-container {
		max-width: 1050px;
		width: 100%;
		margin: 0 auto;
		margin-top: 65px;

		form {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			@include tablet {
				flex-direction: column;
				padding: 0px 6vw;
			}
		}

		.formfield,
		.formfield-files {
			display: flex;
			flex-direction: column;
			width: 45%;
			margin-bottom: 30px;

			@include tablet {
				width: 100%;
			}

			label,
			p {
				color: black;
				margin-bottom: 13px;
				font-size: 15px;
				margin-left: 1px;
				line-height: 1.2;
			}

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px white inset !important;
			}

			input,
			textarea,
			.select-wrapper {
				border: 1px solid white;
				height: 35px;
				padding: 6px 12px;
				background: white !important;

				&:focus {
					border: 1px solid $orange;
				}
			}

			select {
				padding: 6px 12px;
				padding-right: 42px;
				height: 100%;
				background: white;
				border: none;
				width: 100%;
                position: relative;
                text-transform: uppercase;
				-webkit-appearance: none;

				&:focus {
					outline-color: $orange;
				}

				option {
					text-transform: uppercase;
				}
			}

			.select-wrapper {
				position: relative;
				padding: 0;
				height: 47px;
				&:after {
					position: absolute;
					content: '';
					right: 17px;
					top: 39%;
					border: solid black;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 3px;
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					transition: ease-in-out 0.2s all;

					@include tablet {
						right: 20px;
					}
				}
			}

			textarea {
				z-index: 1;
				height: 145px;
			}
		}

		.formfield-files {
			position: relative;
			label {
				height: 50px;
				margin-bottom: 27px;
				display: flex;

				&:hover {
					.two {
						background-color: #bb5c00;
					}
				}

				.one,
				.two {
					height: 100%;
					display: flex;
					align-items: center;
					padding: 0px 16px;
				}

				.one {
					max-width: 272px;
					overflow: hidden;
					background: white;
					flex: 1;
					color: #7779;
					font-size: 12px;
					line-height: 16px;
				}

				.two {
					display: flex;
					justify-content: center;
					background: $orange;
					text-transform: uppercase;
					font-weight: bold;
					color: white;
					flex: 0.5;
					transition: background-color 0.3s ease-in-out;
				}
			}

			input {
				display: none;
			}
		}
	}

	.form-valid {
		width: 100%;
		text-align: center;
		color: $orange;
		font-weight: bold;
		font-size: 14px;
		margin-top: 25px;
		opacity: 0;
	}
}

#onrecrute {
	.item {
		opacity: 0;
		transform: translateY(-20px);
	}

	.offer-title {
		opacity: 0;
		transform: translateY(-20px);
	}

	#rc-form {
		opacity: 0;
		transform: translateY(-20px);
	}

	.alone {
		opacity: 0;
		transform: translateY(-20px);
	}

	.animate {
		animation: slideOut 0.4s 0s forwards;
	}
}

body,
html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

.open-rc {
	max-height: 2300px !important;
	transition: max-height 0.3s ease-in-out;
}

.close-rc {
	max-height: 0px !important;
	transition: max-height 0.3s ease-in-out;
}
