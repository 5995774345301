/*--------------------------------------------------------------
MIXINS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Beziers
- Font-face
- Screen sizes
- Scrollbar
- Full
- Pseudo
- Cols
- Fake
- Button
- Tap
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Beziers
--------------------------------------------------------------*/
$quint: cubic-bezier(.7, 0, .3, 1);



/*--------------------------------------------------------------
	Font-face
--------------------------------------------------------------*/
$weights: ('thin': 100, 'light': 300, 'regular': 400, 'medium': 500, 'bold': 700, 'black': 900);
@mixin fontFace($name, $weight, $italic: false){
	$extW: if($weight == regular and $italic, '', '-'+$weight);
	$extI: if($italic, '-italic', '');
	$url: '/fonts/'+$name+$extW+$extI+'.woff';
	@font-face{
		font-family: $name;
		src: url($url+'2') format('woff2'), url($url) format('woff');
		@if($weight != 'regular'){
			font-weight: map-get($weights, $weight);
		}
		@if($italic){
			font-style: italic;
		}
		font-display: swap;
	}
}



/*--------------------------------------------------------------
	Screen sizes
--------------------------------------------------------------*/
@mixin XXL{@media screen and (min-width: 1920px){@content;}}
@mixin laptopL{@media screen and (max-width: 1440px){@content;}}
@mixin laptop{@media screen and (max-width: 1024px){@content;}}
@mixin tablet{@media screen and (max-width: 768px){@content;}}
@mixin mobileL{@media screen and (max-width: 425px){@content;}}
@mixin mobileM{@media screen and (max-width: 375px){@content;}}
@mixin mobileS{@media screen and (max-width: 320px){@content;}}
@mixin smallH{@media screen and (max-height: 700px){@content;}}



/*--------------------------------------------------------------
	Scrollbar
--------------------------------------------------------------*/
@mixin scrollbar($size, $fg, $bg, $bdr: false){
	::-webkit-scrollbar{
		width: $size;
		height: $size;
	}
	::-webkit-scrollbar-track{
		background: $bg;
	}
	::-webkit-scrollbar-thumb{
		@if $bdr {
			border-radius: $size;
		}
		background: $fg;
		&:hover{
			background: darken($fg, 10);
		}
	}
}



/*--------------------------------------------------------------
	Full
--------------------------------------------------------------*/
@mixin full($margin: 0, $not: 0){
	@if($not != top){
		top: 0;
	}
	@if($not != left){
		left: 0;
	}
	@if($not != right){
		right: 0;
	}
	@if($not != bottom){
		bottom: 0;
	}
	@if($margin == 1){
		margin: auto;
	}
}
@mixin abs($margin: 0, $not: 0){
	position: absolute;
	@include full($margin, $not);
}
@mixin fix($margin: 0, $not: 0){
	position: fixed;
	@include full($margin, $not);
}



/*--------------------------------------------------------------
	Pseudo
--------------------------------------------------------------*/
@mixin pseudo{
	content: '';
	display: block;
}



/*--------------------------------------------------------------
	Cols
--------------------------------------------------------------*/
@function cols($nbr){
	$gaps: $gap * ($nbr - 1);
	@return repeat($nbr, minmax(0, ($layoutW - $gaps)/$nbr));
}
@mixin cols($nbr){
	$gaps: $gap * ($nbr - 1);
	$col: ($layoutW - $gap * 8) / 9;
	max-width: $col * $nbr + $gaps;
	width: calc((100vw - #{$gap * 10}) / 9 * #{$nbr} + #{$gaps});
}



/*--------------------------------------------------------------
	Fake
--------------------------------------------------------------*/
@mixin fake($area, $bg){
	content: '';
	grid-area: $area;
	align-self: stretch;
	background: $bg;
}



/*--------------------------------------------------------------
	Button
--------------------------------------------------------------*/
@mixin button($color, $plain: true, $clickable: false){
	display: inline-block;
	padding: 0 14px;
	font-weight: bold;
	line-height: $btnH;
	@if ($plain) {
		color: #fff;
		background: $color;
	} @else {
		color: $color;
		border: 1px solid $color;
	}
	@if ($clickable) {
		border-radius: $radius;
	}
}



/*--------------------------------------------------------------
-	Tap
--------------------------------------------------------------*/
@mixin tap($color){
	-webkit-tap-highlight-color: $color;
}
