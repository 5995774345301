/*--------------------------------------------------------------
TITLE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/






/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#mention{
	section{
		margin: 0 80px;
		@include tablet{
			margin: 0 60px;
		}
		@include mobileL{
			margin: 0 20px;
		}
		@include mobileM{
			margin: 0 10px;
		}
		@include mobileS{
			margin: 0 5px;
		}
		h1{
			text-align: center;
			font-size: 25px;
			font-weight: 500;
			line-height: 80px;
			color: $orange;
			@include mobileL{
				font-size: 21px;
				line-height: 70px;
			}
			@include mobileM{
				font-size: 19px;
				line-height: 65px;
			}
			@include mobileS{
				font-size: 17px;
				line-height: 60px;
			}
		}
		p{
			font-size: 16px;
			text-align: justify;
			span{
				font-size: 14px;
				font-style: italic;
				font-weight: 700;
				@include mobileS{
					font-size: 13px;
				}
			}
			@include mobileM{
				font-size: 15px;
			}
			@include mobileS{
				font-size: 14px;
			}
		}
	}
}
