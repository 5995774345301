/*--------------------------------------------------------------
LOT > RESUME > DPE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Barre
- En cours
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#zoom_lot{
	.dpe{
		flex-basis: 100%;
		@include tablet{
			padding: $gap;
			background: #fff;
		}
		@include mobileL{
			padding: $gap_mob;
		}
	}
}



/*--------------------------------------------------------------
-	Barre
--------------------------------------------------------------*/
#dpe_bar{
	display: flex;
	position: relative;
	align-items: center;
	user-select: none;
	div{
		position: relative;
		flex: 1 1;
		color: transparent;
		font-size: 16px;
		font-weight: bold;
		height: 1.6em;
		line-height: 1.6em;
		text-align: center;
		$colors: #00dc9c, #5af300, #c9fc00, #fadf00, #ffae00, #fe7120, #db4451;
		@for $i from 1 through length($colors) {
			&:nth-child(#{$i}){
				background: nth($colors, $i);
			}
		}
		&:first-child, &:last-of-type, &.on{
			color: #fff;
		}
		&.on{
			font-size: 24px;
		}
	}
}



/*--------------------------------------------------------------
-	En cours
--------------------------------------------------------------*/
#dpe_bar{
	p{
		@include abs;
		color: $texte;
		font-weight: bold;
		text-align: center;
		line-height: 1.9em;
		background: rgba(#fff, .7);
	}
}
