/*--------------------------------------------------------------
HEADER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Nbr results
- Search recap
- Titre
- Nav
- Filters
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-    Global
--------------------------------------------------------------*/
#recherche{
    header{
		grid-area: h;
        overflow: hidden;
    }
}


/*--------------------------------------------------------------
-    recherche mobile
--------------------------------------------------------------*/
.bouton-mobile-search {
    position: absolute;
    margin-top: $headerH_mob + 20px;
    padding: $gap*0.5;
    z-index: 2;
    left: 0;
    background: $orange;
    stroke: white;
    margin: $gap;
    display: none;
    @include tablet{
        display: inline-block;
    }
    @include mobileL{
        margin: $gap_mob;
        padding: $gap_mob*0.5;
    }
    @include mobileM{
        padding: $gap_mob*0.3;
    }
}


/*--------------------------------------------------------------
-    Nbr results
--------------------------------------------------------------*/
#nbr_results{
    margin: 10px $gap;
    margin-top: $headerH_mob + 20px;
    color: $orange;
    font-weight: 900;
    font-size: 1.4em;
    text-align: center;
    @include tablet{
        margin-top: 30px;
    }
    @include mobileL{
        font-size: 1.2em;
        margin-top: 20px;
    }
    @include mobileS{
        font-size: 1em;
    }
}



/*--------------------------------------------------------------
-    Search recap
--------------------------------------------------------------*/
#search_recap {
}



/*--------------------------------------------------------------
-    Titre
--------------------------------------------------------------*/
#recherche{
    h1{
        margin-bottom: 0px;
        font-size: 1.3em;
        text-align: center;
        color: $scamac;
        @include mobileL{
            font-size: 1.1em;
        }
        @include mobileL{
            font-size: 1.1em;
        }
        @include mobileS{
            font-size: 1em;
        }
    }
}



/*--------------------------------------------------------------
-    Nav
--------------------------------------------------------------*/
#recherche{
    nav{
        display: block;
        float: left;
        margin: 15px 0;
        font-weight: bold;
        li{
            display: inline-block;
            text-align: center;
        }
        a{
            display: block;
            padding: 0 6px;
            line-height: 25px;
        }
		.active a {
			color: $orange;
		}
        @include mobileL{
            float: none;
            text-align: center;
        }
    }
	.results_footer{
        margin-bottom: 50px;
        nav{
            float: none;
        }
	}
}




/*--------------------------------------------------------------
-    Filters
--------------------------------------------------------------*/
#recherche{
    #filters{
        float: right;
        margin: 10px 0;
        @include mobileL{
            float: none;
            margin: 20px 0 0;
            text-align: center;
        }
        button{
            position: relative;
            margin: 0 7px;
            font-weight: bold;
            text-transform: uppercase;
            &:before, &:after{
                content: '';
                display: inline-block;
                box-sizing: border-box;
            }
            &:before{
                width: 20px;
                height: 20px;
                margin-right: 8px;
                vertical-align: -5px;
                border: 1px solid $titre;
                border-radius: $radius;
            }
            &:after{
                position: absolute;
                left: 6px;
                top: 0;
                bottom: 4px;
                width: 8px;
                height: 8px;
                margin: auto;
                border: 2px solid $orange;
                border-top: none;
                border-left: none;
                transition: .2s;
                opacity: 0;
                transform: rotate(45deg);
            }
            &.DESC{
                &:after{
                    opacity: 1;
                }
            }
            &.ASC{
                &:after{
                    top: 4px;
                    bottom: 0;
                    opacity: 1;
                    transform: rotate(-135deg);
                }
            }
        }
    }
}
