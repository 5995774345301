/*--------------------------------------------------------------
MENU  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Li
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
.menu{
	font-size: 12px;
	font-weight: 700;
	letter-spacing: .12em;
	text-transform: uppercase;
}



/*--------------------------------------------------------------
-	Li
--------------------------------------------------------------*/
.menu li{
	display: inline-block;
	line-height: 15px;
	&:after{
		content: '';
		display: inline-block;
		vertical-align: middle;
		margin: 0 25px;
		width: 1px;
		height: 15px;
		background: $trait;
	}
	&:last-child{
		&:after{
			display: none;
		}
	}
}
