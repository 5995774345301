/*--------------------------------------------------------------
BASE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Imports
- Colors
- Sizes
- Fonts
- Scrollbar
- Body
- Links / Buttons
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Imports
--------------------------------------------------------------*/
@import "reset";
@import "mixins";
@import "animations";



/*--------------------------------------------------------------
	Colors
--------------------------------------------------------------*/
$orange : #ee7500;
$bleu   : #0070a0;
$vert   : #34ab5a;
$rouge  : #db343f;

// gris
$scamac      : #2b2b29;
$texte       : #4a4a4a;
$titre       : #9b9b9b;
$placeholder : #bbbbbb;
$trait       : #d5d5d5;
$calque      : #dedede;
$bg          : #efefef;

::selection{
	color: #fff;
	background: $texte;
}



/*--------------------------------------------------------------
	Sizes
--------------------------------------------------------------*/
$layoutW     : 1200px;
$headerH     : 80px;
$headerH_mob : 50px;
$gap         : 20px;
$gap_mob     : 15px;
$pageMargin  : 30px;
$radius      : 5px;
$btnH        : 35px;
$boxing      : calc((100vw - #{$layoutW}) / 2 - #{$gap});



/*--------------------------------------------------------------
	Fonts
--------------------------------------------------------------*/
@include fontFace(kelson, light);
@include fontFace(kelson, regular);
@include fontFace(kelson, medium);
@mixin kelson {
	font-family: kelson, helvetica, arial;
}
body{
	font-family: arial;
	font-size: 14px;
	color: $texte;
}
p, dl{
	line-height: 1.7;
}
h1, h2, h3, h4, .h2_like, .h2like{
	line-height: 1.3;
}



/*--------------------------------------------------------------
	Scrollbar
--------------------------------------------------------------*/
@include scrollbar(6px, $trait, $bg);



/*--------------------------------------------------------------
	Body
--------------------------------------------------------------*/
body{
	position: relative;
	min-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
}



/*--------------------------------------------------------------
	Links / Buttons
--------------------------------------------------------------*/
a, button{
	transition: .2s;
}
a:hover{
	color: $orange;
}
