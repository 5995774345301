/*--------------------------------------------------------------
LOT > RESUME > DESCRIPTION  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- État & dispo
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#zoom_lot{
	.desc{
		@include tablet{
			@include cols(5);
		}
		@include mobileL{
			width: auto;
			max-width: none;
			padding: $gap_mob;
			background: #fff;
		}
		p:not(.h2_like) {
			margin: 10px 0;
		}
	}
}



/*--------------------------------------------------------------
-	État & dispo
--------------------------------------------------------------*/
#zoom_lot{
	.etat_dispo{
		margin-top: 10px;
		font-weight: bold;
		font-style: italic;
		&::before{
			content: '';
			display: inline-block;
			vertical-align: 1px;
			width: 5px;
			height: 5px;
			margin-right: 7px;
			border: 2px solid $scamac;
			border-left: none;
			border-bottom: none;
			transform: rotate(45deg);
		}
		span{
			text-transform: lowercase;
		}
	}
}
