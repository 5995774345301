/*--------------------------------------------------------------
LAYOUT >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Layout
- Titres
- Mosaïque
- Btns
- Cadres Contact & Chat
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Layout
--------------------------------------------------------------*/
body{
	background: $bg;
	@include tap(rgba($scamac, .2));
}
.main{
	max-width: $layoutW;
	margin: auto;
	padding: $headerH $gap 60px;
	@include mobileL{
		padding: $headerH_mob $gap_mob 40px;
	}
}



/*--------------------------------------------------------------
-	Titres
--------------------------------------------------------------*/
.h2like{
	padding: 35px 0;
	text-align: center;
	color: $titre;
	font-size: 20px;
	font-family: kelson;
	letter-spacing: .25em;
	text-transform: uppercase;
	@include mobileL{
		padding: 25px 0;
		font-size: 14px;
		letter-spacing: .2em;
	}
}



/*--------------------------------------------------------------
-	Btns
--------------------------------------------------------------*/
.btn_surface{
	margin-right: 5px;
	@include button($orange);
}
.btn_prix{
	margin-right: 5px;
	@include button($scamac);
	span{
		font-size: 10px;
		font-weight: normal;
		line-height: 0;
	}
}
// .btn_dispo{
// 	margin-right: 15px;
// 	@include button($orange, false);
// 	color: $texte;
// }



/*--------------------------------------------------------------
-	Cadres Contact & Chat
--------------------------------------------------------------*/
.contact, .chat{
	display: flex;
	padding: $gap $gap*2;
	flex-direction: column;
	justify-content: space-around;
	font-size: 1.4em;
	@include kelson;
	text-align: center;
	border: 11px solid $trait;
	background: #fff;
	p{
		margin: auto;
	}
}
.contact{
	svg{
		margin-right: 10px;
		vertical-align: middle;
		stroke: $trait;
	}
	.tel{
		display: inline-block;
		position: relative;
		margin: 5px 0 10px;
		color: $orange;
		font-size: 1.5em;
		font-family: arial;
		font-weight: bold;
		}
		.dev {
			&::before{
				content: "Afficher le numéro";
				@include abs;
				line-height: 48px;
				background-color: $orange;
				color: white;
				font-size: 14px;
				font-family: arial;
				font-weight: bold;
			}
	}
	.email{
		@include button($orange, false, true);
		padding-right: $gap;
		font-size: .8em;
		font-family: arial;
		border-width: 2px;
	}
}
.chat{
	max-width: 100%; // IE
	border-color: $orange;
	svg{
		margin: 0 auto 10px;
		fill: $orange;
	}
}
