/*--------------------------------------------------------------
HEADER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Logo
- Burger
- Menu
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#header{
	display: flex;
	@include fix(1, bottom);
	padding: 0 $gap 15px;
	width: 100%; // IE
	max-width: $layoutW + $gap*2;
	height: $headerH;
	justify-content: space-between;
	align-items: flex-end;
	background: #fff;
	box-sizing: border-box;
	z-index: 10;
	&::before{
		content: '';
		@include fix(0, bottom);
		height: $headerH;
		background: #fff;
		z-index: -1;
	}
	@include laptop{
		padding-bottom: 0;
		align-items: center;
		&::before{
			display: none;
		}
	}
	@include mobileL{
		padding: 0 $gap_mob;
		height: $headerH_mob;
	}
}



/*--------------------------------------------------------------
-	Logo
--------------------------------------------------------------*/
.titled_logo {
	line-height: 1;
}

#logo{
	margin-right: auto; // IE
	img{
		display: block;
		// width: 320px;
		height: 50px; // IE
		@include mobileL{
			width: 190px;
			height: auto;
		}
	}
	strong{
		display: block;
		margin-top: 5px;
		color: $orange;
		font-size: 9px;
		font-weight: bold;
		text-align: center;
		letter-spacing: .5em;
		text-transform: uppercase;
		@include mobileL{
			margin-top: 3px;
			font-size: 7px;
			letter-spacing: 3px;
			padding-left: 2px;
		}
	}
}



/*--------------------------------------------------------------
-	Burger
--------------------------------------------------------------*/
#burger{
	display: none;
	position: relative;
	margin: -15px;
	width: 24px;
	height: 19px;
	border: 15px solid transparent;
	box-sizing: content-box;
	@include laptop{
		display: block;
	}
	span{
		display: block;
		@include abs(1);
		height: 3px;
		background: $orange;
		transform-origin: 100% 0;
		transition: .2s $quint;
		&:first-child{
			bottom: auto;
		}
		&:last-child{
			left: 7px;
			top: auto;
			transform-origin: 100% 100%;
		}
	}
	.open &{
		span:first-child{
			transform: rotate(-45deg);
		}
		span:nth-child(2){
			transform: scaleX(0);
		}
		span:last-child{
			left: 0;
			transform: rotate(45deg);
		}
	}
}



/*--------------------------------------------------------------
-	Menu
--------------------------------------------------------------*/
#header_menu{
	@include laptop{
		display: flex;
		@include fix(0, top);
		top: $headerH;
		background: $orange;
		font-size: 1em;
		opacity: 0;
		visibility: hidden;
		transition: .3s;
		ul{
			margin: auto;
			transition: .3s;
			transform: scale(1.2);
		}
		li{
			display: block;
			margin: 50px;
			text-align: center;
			&:after{
				display: none;
			}
		}
		a{
			color: #fff;
			letter-spacing: .2em;
		}
		.open &{
			opacity: 1;
			visibility: visible;
			ul{
				transform: none;
			}
		}
	}
	@include mobileL{
		top: $headerH_mob;
	}
}
