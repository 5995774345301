/*--------------------------------------------------------------
LOT > RESUME > COMMERCIAUX  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Titre
- Bonhommes
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#zoom_lot{
	.commerciaux{
		display: flex;
		padding: 30px 20px 0;
		flex-basis: 100%;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		border: 10px solid $calque;
		background: #fff;
	}
}



/*--------------------------------------------------------------
-	Titre
--------------------------------------------------------------*/
#zoom_lot{
	.commerciaux{
		h2, .h2_like{
			flex-basis: 100%;
			margin-bottom: 15px;
			text-transform: none;
			color: $texte;
		}
	}
}



/*--------------------------------------------------------------
-	Bonhommes
--------------------------------------------------------------*/
#zoom_lot{
	.commerciaux{
		figure{
			margin: 0 $gap 30px;
		}
		figcaption{
			margin-top: 10px;
			@include mobileL{
				font-size: 18px;
			}
		}
		strong{
			font-weight: bold;
		}
		.tel{
			display: block;
			padding: 5px;
			color: $orange;
			font-weight: bold;
			.txt{
				@include tablet{
					display: none;
				}
			}
			svg{
				display: none;
				@include tablet{
					display: inline-block;
				}
			}
		}
		.dev {
			position: relative;
			&::before{
				content: "Afficher le numéro";
				@include abs;
				text-align: center;
				color: white;
				line-height: 24px;
				font-size: .8em;
				font-weight: bold;
				background-color: $orange;
				cursor: pointer;
				@include tablet{
					display: none;
				}
			}
		}
		.mail, .vcard{
			display: inline-block;
			margin: 10px 5px 0;
			stroke-width: 2px;
		}
		.vcard{
			stroke-width: 1.5px;
		}
		svg{
			stroke: $trait;
			display: inline-block;
			vertical-align: middle;
			@include tablet{
				stroke-width: 1px;
				width: 37px;
				height: 37px;
			}
		}
		.tel, .mail, .vcard{
			@include tablet{
				display: inline-block;
				vertical-align: middle;
				width: 70px;
				height: 70px;
				margin: 10px 10px 0;
				line-height: 66px;
				text-align: center;
				border: 1px solid $trait;
				border-radius: 50%;
			}
		}
	}
}
