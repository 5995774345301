/*--------------------------------------------------------------
LOT > RESUME > GALERIE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#zoom_lot{
	.galerie{
		position: relative;
		height: 0;
		padding-bottom: 65%;
		overflow: hidden;
		background: #fff;
		@include tablet{
			@include cols(4);
			padding-bottom: 28%;
		}
		@include mobileL{
			width: auto;
			max-width: none;
			padding-bottom: 65%;
		}
		figure, img{
			@include abs(1);
		}
		img{
			height: 100%;
			@supports (object-fit: cover) {
				width: 100%;
				object-fit: cover;
			}
		}
		.slider-nav{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0px;
			max-width: $layoutW;
			margin: auto;
			padding: 0 $gap;
			z-index: 3;
			animation: slideIn .4s .7s backwards;
			@include laptop{
				bottom: $gap;
			}
			@include mobileL{
				display: none;
			}
			input{
				display: none;
			}
			label{
				display: inline-block;
				position: relative;
				padding: 7px;
				cursor: pointer;
			}
			span{
				display: block;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background: #fff;
				transition: .2s;
			}
			:hover span, :checked+span{
				background: $orange;
			}
		}
	}
	$sliderDuration: 1.5s;
	section{
		img {
			transition: $sliderDuration $quint;
			transition-property: transform;
		}
		.on{
			z-index: 2;
		}
	}
	.right {
		figure{
			opacity: 0;
			transform: translateX(-100%);
			z-index: 0;
		}
		img{
			transform: translateX(100%);
		}
	}
	.left{
		figure{
			opacity: 0;
			transform: translateX(100%);
			z-index: 1;
		}
		img{
			transform: translateX(-100%);
		}
	}

}
