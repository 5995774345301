/*--------------------------------------------------------------
ARTICLE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Back
- Titre
- Img
- Content
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#article{
	display: flex;
	margin-top: $gap;
	justify-content: space-between;
	@include laptop{
		display: block;
	}
	@include mobileL{
		padding: $headerH_mob $gap_mob 0;
	}
	.article_single{
		display: flex;
		flex-direction: column;
		@include cols(6);
		@include laptop{
			width: auto;
			max-width: none;
			margin-bottom: 20px;
		}
	}
	.aside{
		@include cols(3);
		@include laptop{
			width: auto;
			max-width: none;
		}
	}
}



/*--------------------------------------------------------------
-	Back
--------------------------------------------------------------*/
.back{
	display: inline-block;
	align-self: flex-start;
	position: relative;
	line-height: 47px;
	padding: 0 15px;
	font-size: 14px;
	background: #fff;
	&::before{
		content: '';
		display: inline-block;
		vertical-align: 1px;
		width: 6px;
		height: 6px;
		margin-right: 8px;
		border: 2px solid $orange;
		border-top: none;
		border-right: none;
		transform: rotate(45deg);
	}
}

.breadcrumb {
	text-transform: capitalize;
	display: inline-block;
	align-self: flex-start;
	position: relative;
	line-height: 28px;
	padding: 0 10px;
	font-size: 14px;
	background: #fff;
	margin-top: 10px;
	margin-bottom: 10px;
}


/*--------------------------------------------------------------
-	Titre
--------------------------------------------------------------*/
#article{
	.article_single{
		h1{
			color: $scamac;
			font-size: 2.8em;
			@include kelson;
		}
	}
}



/*--------------------------------------------------------------
-	Img
--------------------------------------------------------------*/
#article{
	.article_single{
		@include tablet{
			align-items: center;
		}
		img{
			display: block;
			position: relative;
			@include cols(4);
			z-index: 1;
			@include laptop{
				@include cols(5);
			}
			@include tablet{
				margin: auto;
				width: 80%;
				max-width: none;
			}
		}
	}
}



/*--------------------------------------------------------------
-	Content
--------------------------------------------------------------*/
#article{
	.article_single{
		.content{
			position: relative;
			@include cols(5);
			margin-top: -60px;
			padding: 80px $gap 40px $gap*2;
			align-self: flex-end;
			background: #fff;
			box-sizing: border-box;
			@include laptop{
				@include cols(7);
			}
			@include tablet{
				padding: 80px $gap_mob 40px $gap_mob;
				width: auto;
				max-width: none;
			}
		}
		h1 {
			@include tablet{
				font-size: 25px;
			}
		}
		p{
			margin-top: 20px;
		}
		dt{
			color: $orange;
			font-weight: bold;
			font-size: 1.2em;
			&+dd p:first-child{
				margin-top: 0;
			}
		}
		dd{
			margin-bottom: 30px;
		}
		ul{
			list-style-type: disc;
			margin-left: 15px;
		}
		ol{
			list-style-type: decimal;
			margin-left: 15px;
		}
		li{
			margin: 5px 0;
		}
	}
}
