/*--------------------------------------------------------------
OUTDATED BROWSERS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#outdated{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 170px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	background: #F2735F;
	z-index: 999;
	* html &{
		position: absolute;
	}
	h6{
		font-size: 25px;
		line-height: 25px;
		margin:30px 0 10px;
	}
	p{
		font-size: 12px;
		line-height: 12px;
		margin: 0;
	}
	.update{
		display: block;
		position: relative;
		padding: 10px 20px;
		margin: 30px auto 0;
		width: 230px;
		color: #fff;
		text-decoration: none;
		border: 2px solid #fff;
		cursor: pointer;
		&:hover{
			color: #f25648;
			background-color: #fff;
		}
	}
	.last{
		position: absolute;
		top: 10px;
		right: 25px;
		width: 20px;
		height: 20px;
	}
	.close{
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		text-decoration: none;
		color: #fff;
		font-size: 36px;
		line-height: 36px;
	}
}
