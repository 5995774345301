/*--------------------------------------------------------------
LOT > GLOBAL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Zoom lot
- Titres
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-    Zoom lot
--------------------------------------------------------------*/
#zoom_lot{
    display: flex;
    justify-content: space-between;
    @include tablet{
        flex-direction: column;
    }
}



/*--------------------------------------------------------------
-	Titres
--------------------------------------------------------------*/
#zoom_lot{
	h2, .h2_like{
        margin-bottom: 10px;
		color: $orange;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

#page_lot.main {
	padding-bottom: 0;
}