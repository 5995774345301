/*--------------------------------------------------------------
SHARE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#page_lot{
	.share{
		display: flex;
		margin: 50px 0 20px;
		align-items: center;
		justify-content: center;
		border: 1px solid $trait;
		border-left: none;
		border-right: none;
		color: $trait;
		@include mobileL{
			margin-top: 30px;
		}
		a, button{
			fill: currentColor;
			stroke: currentColor;
			stroke-width: 2px;
			border: 20px solid transparent;
			&:hover{
				color: $orange;
			}
		}
	}
}
