/*--------------------------------------------------------------
Merci  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#merci{
    .toto{
        margin-top: 80px;
        height: calc(100vh - 290px);
        text-align: center;
        @include laptop{
            margin-top: 70px;
            height: inherit;
        }
        @include tablet{
            height: calc(100vh - 407px);
        }
        @include mobileL{
            margin-top: 40px;
            height: auto;
        }
        .block{
            display: inline-block;
            width: 50%;
            @include mobileL{
                display: block;
                width: 100%;
            }
            h1{
                font-size: 12em;
                font-weight: bold;
                @include laptop{
                    font-size: 10em;
                }
                @include tablet{
                    font-size: 7em;
                }
                @include mobileL{
                    font-size: 5em;
                }
                @include mobileS{
                    font-size: 4em;
                }
            }
            h2{
                font-size: 2.9em;
                @include laptop{
                    font-size: 2.2em;
                }
                @include tablet{
                    font-size: 1.8em;
                }
                @include mobileL{
                    font-size: 1.7em;
                }
                @include mobileS{
                    font-size: 1.5em;
                }
            }
            p{
                color: $orange;
                margin-top: 25px;
                font-size: 18px;
                @include laptop{
                    font-size: 17px;
                }
                @include tablet{
                    font-size: 16px;
                }
                :hover{
                    color: black;
                }
            }
            ul{
                li{
                    line-height: 20px;
                    font-size: 16px;
                    @include laptop{
                        font-size: 15px;
                    }
                    @include tablet{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
