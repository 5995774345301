/*--------------------------------------------------------------
POPUP  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Hidden
- Cache
- Wrapper
- Btn
- Big
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#popup{
	display: flex;
	@include fix;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	z-index: 100;
	overflow: auto;
	overscroll-behavior: contain;
	transition: {
		property: opacity, visibility;
		duration: .3s;
	}
	@include mobileL{
		display: block;
	}
	&.on{
		opacity: 1;
		visibility: visible;
	}
}



/*--------------------------------------------------------------
-	Hidden
--------------------------------------------------------------*/
@mixin popup_hidden {
	opacity: 0;
	visibility: hidden;
	transform: scale(.8);
}



/*--------------------------------------------------------------
-	Cache
--------------------------------------------------------------*/
#popup{
	.cache{
		@include fix;
		background: rgba(#000, .5);
	}
}



/*--------------------------------------------------------------
-	Wrapper
--------------------------------------------------------------*/
#popup{
	.wrapper{
		position: relative;
		margin: $gap;
		max-width: 500px;
		padding: 40px;
		@include kelson;
		font-size: 1.2em;
		text-align: center;
		background: #fff;
		box-sizing: border-box;
		transition: {
			property: opacity, visibility, transform;
			duration: .3s;
		}
		&.hidden{
			@include popup_hidden;
		}
	}
	&:not(.on){
		.wrapper{
			@include popup_hidden;
		}
	}
}



/*--------------------------------------------------------------
-	Btn
--------------------------------------------------------------*/
#popup{
	.btn{
		margin: 15px 5px 0;
		@include button($orange, true, true);
		font-weight: normal;
		&:hover{
			background: darken(saturate($orange, 10), 10);
		}
		&.no{
			background: $texte;
			&:hover{
				background: darken($texte, 10);
			}
		}
	}
}



/*--------------------------------------------------------------
-	Big
--------------------------------------------------------------*/
#popup{
	.big{
		text-transform: uppercase;
		font-size: 1.4em;
	}
}
