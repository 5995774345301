/*--------------------------------------------------------------
SEARCH MODULE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Vars
- Fieldset
- Labels / Inputs
- Types de biens
- Surface
- Localisation
- Zone personnalisée
- Submit
- Plus de critères
- Référence
- Propriétaire
- Autocomplete
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Vars
--------------------------------------------------------------*/
$tabsH: 47px;



/*--------------------------------------------------------------
-	Fieldset
--------------------------------------------------------------*/
.search>fieldset, .search form{
	display: none;
	position: relative;
	padding: $gap;
	color: $scamac;
	@include mobileL{
		padding: $gap_mob;
	}
	&.first{
		animation: fadeIn .3s .8s backwards;
	}
	&.on{
		display: block;
		z-index: 1;
	}
}



/*--------------------------------------------------------------
-	Labels / Inputs
--------------------------------------------------------------*/
.search{
	label{
		display: inline-block;
		position: relative;
		margin: $gap/2;
		margin-left: 0;
		padding: 0 $gap*1.5 0 $gap/2;
		line-height: $btnH;
		font-weight: bold;
		border: 1px solid $trait;
		box-sizing: border-box;
		@include mobileL{
			margin: $gap_mob/2;
			padding: 0 $gap_mob*1.5 0 $gap_mob/4;
			font-size: 11px;
		}
		span{
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			padding: 0 $gap/2;
			right: 0;
		}
	}
	input{
		width: 100%;
		height: 100%;
		font-weight: normal;
		border: none;
		box-sizing: border-box;
		&::placeholder{
			color: $placeholder;
		}
	}
}



/*--------------------------------------------------------------
-	Types de biens
--------------------------------------------------------------*/
.search .types{
	li {
		display: inline-block;
	}
	label {
		margin-top: 0;
		padding: 0 21px 0 10px;
		color: $placeholder;
		border-radius: $radius;
		user-select: none;
		transition: .2s;
		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 5px;
			right: 10px;
			width: 4px;
			margin: auto;
			height: 8px;
			border: 2px solid $orange;
			border-left: none;
			border-top: none;
			transform: rotate(45deg);
			transition: .1s;
			opacity: 0;
			transform: translateY(10px) rotate(45deg);
		}
		&.off {
			display: none;
		}
	}
	input{
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;
		&:checked + label{
			color: inherit;
			&:after{
				opacity: 1;
				transform: rotate(45deg);
			}
		}
	}
}


/*--------------------------------------------------------------
-	Plus mobile
--------------------------------------------------------------*/
#plus-home-search {
	position: relative;
	background-color: white;
	color: #292d27;
	margin-top: 0;
  padding: 10px;
	margin: $gap/2;
	margin-top: 0;
	margin-left: 0;
	border-radius: $radius;
	font-size: 14px;
	border: 1px solid #d5d5d5;
	box-shadow: none;
  display: inline-block;
	span {
		pointer-events: none;
	}
	@media screen and (max-width: 350px) {
		margin-left: $gap/2;
	}
}

/*--------------------------------------------------------------
-	Surface
--------------------------------------------------------------*/
.search .surface input{
	width: 100px;
	@include mobileS{
		width: 70px;
	}
}



/*--------------------------------------------------------------
-	Localisation
--------------------------------------------------------------*/
.search .place{
	position: relative;
	height: $btnH + $gap;
	input:disabled {
		background-color: white;
	}
	label{
		position: absolute;
		&:first-of-type{
			left: 0;
			right: 100px;
			padding: 0 $gap/2 0 $gap*1.5;
		}
		&:nth-of-type(2){
			right: 0;
			width: 100px - $gap/2;
			&.disabled {
				background-color: silver;
				cursor: not-allowed;
				input {
					cursor: not-allowed;
					background-color: silver;
				}
			}
		}
	}
	svg{
		@include abs(1, right);
		margin-left: $gap/2;
		vertical-align: -3px;
	}
}
.search #cities .city {
	background-color: $trait;
	border-radius: 5px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	display: inline-block;
	min-width: 100px;
	position: relative;
	margin: 5px;
	padding-right: 30px;
	padding-left: 10px;

	span.delete {
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		height: 10px;
		width: 10px;
		display: inline-block;
		background : {
			image : url('/img/pictos/cross.svg');
			repeat : no-repeat;
			size : contain;
		};
	}
}
#polygon {
	color: white;
	background-color: #0070a0;
	border-radius: 5px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	display: block;
	width: 150px;
	position: relative;
	padding-right: 35px;
	padding-left: 15px;
	display: none;
	position: absolute;
	z-index: 2;
	top: 50%;
	transform: translateY(-50%);
	left: 30px;

	span.delete {
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		height: 10px;
		width: 10px;
		display: inline-block;
		background : {
			image : url('/img/pictos/cross.svg');
			repeat : no-repeat;
			size : contain;
		};
	}
}



/*--------------------------------------------------------------
-	Zone personnalisée
--------------------------------------------------------------*/
.search .zone{
	position: relative;
	@include button($bleu, true, true);
	margin-top: $gap/2;
	padding-right: 30px;
	box-shadow: 0 2px 3px rgba(#000, .2);
	@include mobileL{
		margin-top: 0;
		margin: $gap/2;
	}
	&:before{
		content: '';
		position: absolute;
		left: 13px;
		bottom: 9px;
		height: 1px;
		width: 15px;
		background: #fff;
		transition: .3s;
		transform-origin: 0 0;
		transform: scaleX(0);
	}
	span{
		display: inline-block;
		transition: .3s;
	}
	svg{
		margin-right: 10px;
		stroke: currentColor;
		vertical-align: -4px;
		transition: .3s;
	}
	&:hover{
		svg, span{
			transform: translateX(15px);
		}
		&:before{
			transform: scaleX(1);
		}
	}
}



/*--------------------------------------------------------------
-	Submit
--------------------------------------------------------------*/
.search .submit{
	@include button($orange, false, true);
	display: block;
	margin: $gap*1.5 auto;
  margin-bottom: $gap*2;
	padding: 0 $gap;
	height: $gap*2.5;
	border-width: 2px;
	box-shadow: 0 4px 7px rgba($orange, .1);
	&:before{
		content: '';
		display: inline-block;
		margin-right: $gap/2;
		width: 0;
		height: 0;
		border: 4px solid transparent;
		border-left: 7px solid $scamac;
		border-right: none;
		animation: slideOutInTriangle .3s backwards;
	}
	&:hover{
		&:before{
			animation: slideInOutTriangle .3s both;
		}
	}
}
@keyframes slideInOutTriangle {
	49%{
		opacity: 0;
		transform: translateX(15px);
	}
	50%{
		opacity: 0;
		transform: translateX(-15px);
	}
	100%{
		border-left-color: $orange;
	}
}
@keyframes slideOutInTriangle {
	0%{
		border-left-color: $orange;
	}
	49%{
		opacity: 0;
		transform: translateX(-15px);
	}
	50%{
		opacity: 0;
		transform: translateX(15px);
	}
}

/*--------------------------------------------------------------
-	Alerte Mobile
--------------------------------------------------------------*/
#alert-home-mobile {
	position: relative;
	@include button(white, false, true);
	background-color: $orange;
	margin: $gap_mob*1.5 auto;
	padding: 0 $gap_mob;
	height: $gap_mob*2.5;
	border-width: 2px;
	box-shadow: 0 4px 7px rgba($orange, .1);
	display: none;
	svg {
		height: 80%;
		fill: white;
		vertical-align: -8px;
	}
	@include tablet{
		display: block;
	}
}


/*--------------------------------------------------------------
-	Plus de critères
--------------------------------------------------------------*/
#more_criteria{
	display: block;
	position: relative;
	margin-bottom: 10px;
	padding-left: 25px;
	@include tablet{
		display: none;
	}
	&:before, &:after{
		content: '';
		@include abs(1, right);
		border: 1.5px solid currentColor;
	}
	&:before{
		width: 14px;
		height: 0;
	}
	&:after{
		left: 7px;
		height: 14px;
		width: 0;
	}
}



/*--------------------------------------------------------------
-	Référence
--------------------------------------------------------------*/
.search .ref{
	position: absolute;
	margin: 0;
	padding: 0;
	height: auto;
	bottom: $gap;
	right: $gap;
	color: $orange;
	border: none;
	@include tablet{
		position: relative;
		margin-top: $gap_mob;
		width: 100%;
		text-align: center;
		right: 0;
	}
	input{
		height: 30px;
		width: 170px;
		border-bottom: 1px solid $trait;
		text-transform: uppercase;
		&::placeholder{
			color: currentColor;
			text-transform: none;
		}
		&:focus{
			&::placeholder{
				color: transparent;
			}
		}
	}
	svg{
		margin-left: $gap/2;
		vertical-align: middle;
		stroke: currentColor;
		transition: .2s;
		&:hover{
			transform: scale(1.2);
		}
	}
}



/*--------------------------------------------------------------
-	Autocomplete
--------------------------------------------------------------*/
.autocomplete_list{
	position: absolute;
	left: 2px;
	top: 50px;
	z-index: 9;
	box-shadow: 0 2px 6px rgba(0,0,0,0.3);
	background-color: white;

	li {
		padding: 1px;
		padding: 10px 5px;
		padding-left: 30px;
		border-top: 1px solid #e6e6e6;
		color: black;
		text-transform: capitalize;
		text-align: left;
		cursor: pointer;

		&.active {
			background-color: darken(white, 5);
			&:before {
				content: '';
				width: 0;
				height: 0;
				border: 5px solid transparent;
				border-right-width: 0px;
				border-left-width: 10px;
				border-left-color: #eb7625;
				position: absolute;
				left: 15px;
			}
		}

		b {
			font-weight: bold;
			color: black;
		}
		span {
			color: #999;
		}
	}
}
