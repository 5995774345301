/*--------------------------------------------------------------
ALERT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Form
- Loading
- Response
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#alert{
	@extend #popup;
	.wrapper{
		padding: 25px 30px;
		border: 10px solid $orange;
	}
	button:not(.close){
		@include button($orange, true, true);
		margin-top: 20px;
		padding: 0 30px;
		font-size: 14px;
		line-height: 50px;
		letter-spacing: .1em;
		text-transform: uppercase;
		font-family: arial, sans-serif;
		&:hover{
			background: darken(saturate($orange, 10), 10);
		}
	}
}



/*--------------------------------------------------------------
-	Form
--------------------------------------------------------------*/
#alert{
	form.wrapper{
		font-size: 1em;
		font-family: arial, sans-serif;
	}
	h1, .h1_like{
		margin-bottom: 20px;
		color: $orange;
		@include kelson;
		font-size: 1.4em;
		&+p{
			@include kelson;
			font-size: 1.2em;
			color: $placeholder;
		}
	}
	ul{
		margin: 15px 0 30px;
	}
	li{
		display: inline-block;
		margin: 5px;
		padding: 0 10px;
		line-height: 25px;
		border: 1px solid $trait;
		border-radius: 5px;
	}
	input{
		display: block;
		margin-bottom: 10px;
		padding: 0 15px;
		width: 100%;
		font-size: 15px;
		height: 40px;
		line-height: 40px;
		border: 1px solid $bg;
		background: #f7f8fa;
		box-sizing: border-box;
		&::placeholder{
			color: $placeholder;
		}
	}
	.legal{
		margin-top: 25px;
		font-size: 13px;
		line-height: 1.3;
		color: $placeholder;
	}
	.close{
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		height: 25px;
		&::before, &::after{
			content: '';
			@include abs(1);
			width: 3px;
			height: 100%;
			background: $trait;
		}
		&::before{
			transform: rotate(45deg);
		}
		&::after{
			transform: rotate(-45deg);
		}
	}
}



/*--------------------------------------------------------------
-	Loading
--------------------------------------------------------------*/
#alert{
	&::before{
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50px;
		height: 50px;
		margin: -25px;
		border: 2px solid #fff;
		border-top-color: transparent;
		border-radius: 50%;
		opacity: 0;
		transition: .5s;
		animation: spin linear 1s infinite;
		z-index: 1;
	}
	&::after{
		content: '';
		display: none;
		@include fix;
		z-index: 2;
	}
	&.loading{
		&::before{
			opacity: 1;
		}
		&::after{
			display: block;
		}
	}
}



/*--------------------------------------------------------------
-	Response
--------------------------------------------------------------*/
#alert{
	.wrapper.response{
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin: auto;
		transform: translateY(-50%);
		&.fail{
			border-color: $rouge;
			button{
				background: $rouge;
			}
		}
		&.hidden{
			transform: translateY(-50%) scale(.8);
		}
		.hidden{
			display: none;
		}
	}
	&:not(.on){
		.wrapper.response{
			transform: translateY(-50%) scale(.8);
		}
	}
}
