/*--------------------------------------------------------------
SIDEBAR  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Btns
- Search
- Chat
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
$sidebar_shadow: 0 0 15px rgba(#000, .1);
#sidebar{
	position: fixed;
	right: 0;
	bottom: 100px;
	width: 70px;
	transition: .5s;
	z-index: 2;
	box-shadow: $sidebar-shadow;
	animation: IHaveShadow .5s 1.3s backwards;
	@include laptopL{
		box-shadow: none;
	}
	@include tablet{
		right: -150px;
		bottom: $gap;
		animation: none;
	}
	@include mobileL{
		width: 50px;
		bottom: $gap_mob;
		z-index: 9;
	}
	&::before, &::after{
		content: '';
		@include abs(1, left);
		z-index: -1;
		transition: .4s .2s;
		animation: ImHere .4s 3s backwards;
	}
	&::before{
		right: -30px;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background: #fff;
		box-shadow: $sidebar_shadow;
	}
	&::after{
		left: 50px;
		width: 12px;
		height: 12px;
		border: 2px solid $orange;
		border-top: none;
		border-right: none;
		transform: rotate(45deg);
	}
	&:hover{
		box-shadow: $sidebar_shadow;
		transition-delay: .2s;
		a, button{
			transform: none;
		}
		&::before, &::after{
			transition-delay: 0s;
			transform: translateX(50px);
		}
	}
}
@keyframes IHaveShadow {
	0%{
		box-shadow: none;
	}
}
@keyframes ImHere {
	0%{
		transform: translateX(50px);
	}
}




/*--------------------------------------------------------------
-	Btns
--------------------------------------------------------------*/
#sidebar{
	.btn{
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		height: 70px;
		width: 100%;
		color: $orange;
		fill: currentColor;
		stroke: currentColor;
		border-bottom: 1px solid $bg;
		z-index: 0;
		transition: {
			property: color, transform;
			duration: .2s;
		}
		animation: WeAreHereToo 2s backwards;
		@include laptopL{
			transform: translateX(100%);
		}
		@for $i from 1 through 5{
			&:nth-child(#{$i}){
				$delay: $i*.05 - .05s;
				transition-delay: 0s, $delay, $delay;
				animation-delay: $delay + 1s;
			}
		}
		&:last-child{
			border: none;
		}
		&::before, &::after{
			content: '';
			@include abs;
			background: #fff;
			z-index: -2;
		}
		&::after{
			background: $orange;
			z-index: -1;
			backface-visibility: hidden;
			transform: scaleX(0);
			transform-origin: 100% 0;
			transition: .2s;
		}
		&:hover, &.on{
			color: #fff;
			&::after{
				transform: none;
			}
		}
	}
}
@keyframes WeAreHereToo {
	0%{
		transform: translateX(100%);
	}
	15%, 85%{
		transform: none;
	}
}



/*--------------------------------------------------------------
-	Search
--------------------------------------------------------------*/
#sidebar{
	#home + &{
		.btn_search{
			display: none;
		}
	}
}



/*--------------------------------------------------------------
-	Chat
--------------------------------------------------------------*/
#sidebar{
	.btn_chat{
		@include tablet{
			left: -150px - $gap;
			color: #fff;
			border-radius: 50%;
			background: $orange;
			overflow: hidden;
			transform: none;
			transform-origin: 100% 100%;
			animation: ChatMobIsHere .2s 3s backwards;
			&::before, &::after{
				display: none;
			}
		}
		@include mobileL{
			height: 50px;
			left: -150px - $gap_mob;
		}
		// &.unavailable{
		// 	display: none;
		// }
		&.new{
			svg{
				animation: shake_dat_booty 2s infinite;
			}
		}
		svg{
			transition: opacity .2s;
			@include tablet{
				fill: #fff;
			}
		}
		span{
			@include abs;
			opacity: 0;
			transition: opacity .2s;
			&::before, &::after{
				content: '';
				@include abs(1);
				margin: auto;
				width: 3px;
				height: 30px;
				background: #fff;
				transform: rotate(45deg);
				z-index: 1;
				@include mobileL{
					background: $orange;
				}
			}
			&::after{
				transform: rotate(-45deg);
			}
		}
		&.on{
			transform: none;
			@include mobileL{
				color: $orange;
				border: 1px solid $trait !important;
				background: #fff;
				transition: transform .3s;
				transform: scale(.8);
			}
			svg{
				opacity: 0;
			}
			span{
				opacity: 1;
			}
		}
	}
}
@keyframes shake_dat_booty {
	70%, 100%{
		transform: none;
	}
	75%, 85%, 95%{
		transform: translateX(5px);
	}
	80%, 90%{
		transform: translateX(-5px);
	}
}
@keyframes ChatMobIsHere {
	0%{
		opacity: 0;
		transform: scale(.5);
	}
}
