/*--------------------------------------------------------------
LOT > PRINT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Lot
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
body{
	background: none;
}
#header{
	position: relative;
	padding: 0;
	height: auto;
	justify-content: center;
	&::before{
		display: none;
	}
	.menu{
		display: none;
	}
}
#sidebar{
	display: none;
}
footer{
	display: none;
}
.main{
	padding: 0;
}



/*--------------------------------------------------------------
-	Lot
--------------------------------------------------------------*/
#page_lot{
	.back{
		display: none;
	}
	.header_lot{
		display: block;
	}
	h1{
		padding: 25pt 0;
		font-size: 14pt;
	}
	.share{
		display: none;
	}
	.mosaique{
		display: none;
	}
}
#zoom_lot{
	display: block;
	.resume, .details{
		max-width: none;
		width: auto;
	}
	.resume{
		page-break-after: always;
	}
	tr:nth-child(even){
		background: lighten($bg, 3);
	}
	.galerie{
		float: left;
		width: 50%;
		margin-right: 4%;
		padding-bottom: 32%;
	}
	.dpe{
		// clear: both;
	}
	.adresse{
		clear: both;
	}
	#map_city{
		display: none;
	}
	.commerciaux{
		padding: 20px;
		figure{
			margin: 0;
		}
		.vcard{
			display: none;
		}
		.mail{
			margin: 2px 0 0;
			&::before{
				content: attr(data-email);
				font-size: 12px;
			}
			svg{
				display: none;
			}
		}
	}
}
#dpe_bar{
	div, p{
		-webkit-print-color-adjust: exact;
	}
}
