.seo-blocks {

	margin-top: 10px;
	margin-bottom: 10px;

	.seo-block {
		margin-top: 10px;
		margin-bottom: 10px;
		display: inline-block;
		width: 100%;
		-webkit-column-count: 4;
		column-count: 4;

		a {
			//text-transform: lowercase;
			display: inline-block;
			padding: 0;
			font-size: 12px;
			margin-top: 2px;
			margin-bottom: 2px;

			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 100%;

			&:hover {
				text-decoration: underline;
			}
		}

		@media screen and (max-width: 1080px) {
			-webkit-column-count: 3;
			column-count: 3;
		}

		@media screen and (max-width: 820px) {
			-webkit-column-count: 2;
			column-count: 2;
		}
	}
}