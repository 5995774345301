/*--------------------------------------------------------------
FEATURED LOT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
.featured_lot{
	@extend .mosaique;
	margin: 0;
	padding: 0 $gap;
	text-align: left;
	border: 1px solid $trait;
	border-top: none;
	border-bottom: none;
	box-sizing: border-box;
	animation: fadeIn .5s .5s backwards;
	@include tablet{
		border: none;
		padding-left: 0;
		padding-right: 0;
		#home &{
			padding-left: $gap;
			padding-right: $gap;
		}
	}
	@include mobileL{
		padding: 0 $gap_mob;
		#home &{
			padding-left: $gap_mob;
			padding-right: $gap_mob;		
		}
	}
	figure{
		position: relative;
		margin-left: 0;
		margin-right: 0;
	}
	figcaption{
		display: inline-block;
		position: absolute;
		padding: 5px 10px;
		bottom: $gap;
		left: $gap;
		color: $orange;
		font-size: 1.3em;
		font-weight: bold;
		text-transform: uppercase;
		background: #fff;
	}
	.texte {
		@include mobileL{
			display: none;
		}
	}
}
