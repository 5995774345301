/*--------------------------------------------------------------
CHAT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Header
- Messages
- User area
- Textarea
- Buttons
- Min / max
- Loader
- Close chat end
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#chat_box{
    position: fixed;
    bottom: 100px;
    right: 70px;
    width: 400px;
    box-shadow: 0 0 10px rgba($orange, .3);
    background: $orange;
    z-index: 8;
    transition: {
        property: opacity, visibility, transform;
        duration: .2s;
    }
    &:not(.open){
        opacity: 0;
        visibility: hidden;
        transform: translateY(30px);
    }
	@include tablet{
        right: $gap;
		bottom: $gap * 2 + 70;
	}
    @include mobileL{
        display: flex;
        top: $headerH_mob;
        left: 0;
        right: 0;
        width: auto;
        flex-direction: column;
        &.open{
            height: calc(100% - #{$headerH_mob});
        }
        &.open + .main{
            display: none;
        }
    }
}



/*--------------------------------------------------------------
-    Messages
--------------------------------------------------------------*/
#chat_box{
    @include scrollbar(3px, lighten($orange, 25), $orange, true);
    .messages{
        display: flex;
        // min-height et max-height pour IE (ça bug chelou avec juste un height)
        min-height: 450px;
        max-height: 450px;
        margin-bottom: auto;
        padding: 10px 20px 0;
        flex-direction: column;
        align-items: flex-start;
        word-wrap: break-word;
        overflow-y: scroll;
        overscroll-behavior: contain;
        @include mobileL{
            height: auto;
            padding: 10px $gap_mob;
        }
    }
    .message{
        position: relative;
        margin: 15px;
        padding: 15px 25px;
        max-width: 80%;
        line-height: 1.5;
        background: #fff;
        border-radius: 0 8px 8px 8px;
        box-sizing: border-box;
        animation: chat_message .3s backwards;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: -15px;
            width: 0;
            height: 0;
            border-right: 15px solid #fff;
            border-bottom: 15px solid transparent;
        }
		&.robot{
            color: $orange;
		}
		&.error{
			color: $rouge;
		}
        &.client{
            align-self: flex-end;
            color: $texte;
            border-radius: 8px 0 8px 8px;
            animation-name: chat_message_client;
            &::before{
                left: auto;
                right: -15px;
                border-right: none;
                border-left: 15px solid #fff;
            }
        }
		a{
			color: $orange;
		}
    }
}
@keyframes chat_message {
    0%{
        opacity: 0;
        transform: translateX(-20px) scale(.9);
    }
}
@keyframes chat_message_client {
    0%{
        opacity: 0;
        transform: translateX(20px) scale(.9);
    }
}



/*--------------------------------------------------------------
-	User area
--------------------------------------------------------------*/
#chat_box{
	.user_area{
        position: relative;
		margin: $gap;
		line-height: 1.5;
        @include mobileL{
            margin: 0;
            padding-left: $gap_mob;
            padding-right: $gap_mob * 2 + 40px;
            background: #fff;
        }
	}
    input, .textarea{
        box-sizing: border-box;
        padding: 15px 25px;
        width: 100%;
		line-height: inherit;
        border: none;
        outline: none;
        background: #fff;
        @include mobileL{
            height: 40px;
            margin: $gap_mob 0;
            padding: 0 35px 0 $gap_mob;
            font-size: 1rem; // prevents iOS zoom
            border: 1px solid $trait;
        }
        &::placeholder{
            color: inherit;
            opacity: .5;
        }
    }
}



/*--------------------------------------------------------------
-    Textarea
--------------------------------------------------------------*/
#chat_box{
    .textarea{
        position: relative;
        @include mobileL{
            padding: 9px 45px 9px $gap_mob;
            height: auto;
        }
        &:empty::before{
            content: 'Envoyer un message...';
            cursor: text;
            opacity: .5;
        }
    }
    .send{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 72px;
        width: 30px;
        height: 30px;
        margin: auto;
        cursor: pointer;
        @include mobileL{
            display: block;
        }
        &::before, &::after{
            content: '';
            @include abs(1);
            border: 2px solid transparent;
            border-right: none;
            width: 0;
            height: 0;
        }
        &::before{
            border-width: 8px;
            border-left: 15px solid $orange;
        }
        &::after{
            margin-right: 12px;
            border-left: 11px solid #fff;
        }
    }
    .special{
        .textarea, .textarea+.send{
            display: none;
        }
    }
}



/*--------------------------------------------------------------
-    Buttons
--------------------------------------------------------------*/
#chat_box{
	.btns{
        position: relative;
		background: #fff;
        @include mobileL{
            padding: $gap_mob 0;
        }
	}
    button{
        @include button($trait, false, true);
		position: relative;
		margin: 5px;
        padding: 0 25px 0 10px;
        color: $placeholder;
        @include mobileL{
            margin: 2px;
        }
        &::after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 5px;
			right: 10px;
			width: 4px;
			margin: auto;
			height: 8px;
			border: 2px solid $orange;
			border-left: none;
			border-top: none;
			transform: rotate(45deg);
			transition: .1s;
			opacity: 0;
			transform: translateY(10px) rotate(45deg);
		}
        &.on{
            color: inherit;
            &:after{
                opacity: 1;
                transform: rotate(45deg);
            }
        }
    }
    .submit{
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0 20px;
        color: #fff;
        text-transform: uppercase;
        border: none;
        border-radius: 0;
        background: $orange;
        @include mobileL{
            position: relative;
            margin-top: 13px;
        }
        &:hover{
            background: darken($orange, 5);
        }
    }
    .yep_nope{
        padding: 0 20px;
        color: $orange;
        border: none;
        background: #fff;
        @include mobileL{
            margin: $gap_mob 0;
            margin-right: $gap_mob;
            border: 1px solid $trait;
        }
    }
}



/*--------------------------------------------------------------
-    Min / max
--------------------------------------------------------------*/
#chat_box{
    input[type=number]{
        width: calc(50% - 23px);
        padding-right: 25px;
        &:first-of-type{
            margin-right: 4px;
        }
        @include mobileL{
            margin: $gap_mob 0;
            width: 100%;
            &:first-of-type{
                margin-bottom: 0;
            }
        }
    }
	.submit_min_max{
        margin: 0 0 0 4px;
        padding: 0;
        line-height: 51px;
        width: 38px;
		color: #fff;
        text-align: center;
		text-transform: uppercase;
		border: none;
		border-radius: 0;
		background: $orange;
        &:hover{
            background: darken($orange, 5);
        }
        @include mobileL{
            position: absolute;
            top: $gap_mob;
            right: $gap_mob;
            line-height: 40px;
            color: $orange;
            background: none;
            &:hover{
                background: none;
            }
        }
	}
}



/*--------------------------------------------------------------
-    Loader
--------------------------------------------------------------*/
#chat_box{
    .loader{
		position: relative;
        width: 50px;
        flex: 0 0 50px;
        align-self: center;
        overflow: hidden;
		&::before{
            content: '';
			@include abs;
			border: 2px solid #fff;
			border-top-color: transparent;
			border-bottom-color: transparent;
			border-radius: 50%;
			animation: spin linear 1s infinite;
		}
    }
}



/*--------------------------------------------------------------
-    Close chat end
--------------------------------------------------------------*/
#chat_box{
    .close_chat{
        display: block;
        margin: auto;
        padding: 0 20px;
        color: #fff;
        border-color: #fff;
        &:hover{
            background: darken($orange, 10);
        }
        @include mobileL{
            margin: $gap_mob auto;
            color: $orange;
            border: 1px solid $trait;
        }
    }
}
