/*--------------------------------------------------------------
CONTACT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Formulaire
- Vcards
- Carte
- Addresse
--------------------------------------------------------------*/


/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#contact{
	display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include tablet{
        flex-direction: column;
    }
    .background{
        @include abs;
        height: 485px;
        background: url('/img/siege.jpg') no-repeat top center;
        background-size: cover;
    }
}



/*--------------------------------------------------------------
-    Formulaire
--------------------------------------------------------------*/
#contact_form{
    position: relative;
    @include cols(6);
    margin: $gap 0 60px;
    padding: $gap $gap 80px;
    box-sizing: border-box;
    background: #fff;
    @include tablet{
        width: auto;
        max-width: none;
        margin-top: $gap_mob;
    }
    h2{
        margin-bottom: $gap;
        font-size: 2.9em;
        font-weight: 500;
        font-family: kelson;
        @include mobileL{
            font-size: 1.7em;
            text-align: center;
        }
    }
    p{
        font-size: 1.2em;
        @include mobileL{
            font-size: 1em;
        }
    }
    .subtitle{
        margin: 15px 0;
        @include mobileL{
            text-align: center;
        }
    }
    label{
        display: block;
        margin: 15px 0;
        span{
            color: $orange;
        }
    }
    input, textarea{
        width: 100%;
        padding: 15px $gap;
        box-sizing: border-box;
        font-size: 13px;
        border: 1px solid $trait;
        transition: background .2s;
        &::placeholder{
            color: $placeholder;
        }
        &:focus{
            border: 1px solid $trait;
            background: #f7f8fa;
        }
    }
    .prenom, .nom{
		display: inline-block;
        width: calc(50% - #{$gap} / 2);
        @include mobileL{
            width: 100%;
        }
    }
    .prenom{
        margin-right: $gap;
        @include mobileL{
            margin-bottom: 0;
            margin-right: 0;
        }
    }
    textarea{
        min-width: 100%;
        max-width: 100%;
        height: 190px;
        border: 1px solid $trait;
    }
    button[type=submit]{
        position: absolute;
        left: 0;
        right: 0;
        bottom: -25px;
        margin: auto;
        height: 50px;
        width: 290px;
        @include button($orange);
        text-transform: uppercase;
        border-radius: 4px;
        box-shadow: 0 4px 7px rgba($orange, .1);
        transition: .2s;
        &::before{
            content: '';
            @include abs(1);
            width: 25px;
            height: 25px;
            border: 2px solid $trait;
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-radius: 50%;
            opacity: 0;
            transition: opacity .3s;
            animation: spin linear 1s infinite;
        }
        @include mobileL{
            width: calc(100% - #{$gap * 2});
        }
        &:hover{
            background: darken($orange, 10);
        }
        &:disabled{
            color: transparent;
            background: $texte;
            cursor: not-allowed;
            &::before{
                opacity: 1;
            }
        }
    }
	.info{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 50px;
		font-size: 1em;
		text-align: center;
        opacity: 0;
        transition: opacity .2s;
		&.pending, &.done, &.failed{
            opacity: 1;
            animation: fadeOut .3s 3s both;
		}
        &.pending{
			color: $titre;
		}
        &.done{
			color: $vert;
		}
		&.failed{
            color: $rouge;
		}
	}
}



/*--------------------------------------------------------------
-    Vcards
--------------------------------------------------------------*/
#contact{
    .vCard{
        position: relative;
        @include cols(3);
        top: -50px;
        align-self: flex-end;
        @include laptop{
            @include cols(3);
        }
        @include tablet{
            @include cols(4);
            align-self: flex-start;
            margin-top: 80px;
        }
		@include mobileL{
            width: auto;
            max-width: none;
            margin-top: 50px;
            align-self: stretch;
		}
        h3{
            font-size: 2.15em;
            font-family: kelson;
            font-weight: 500;
            margin-bottom: 10px;
            margin-left: 23px;
            @include mobileL{
                font-size: 1.8em;
                text-align: center;
                margin-bottom: 18px;
            }
        }
        hr{
            border: none;
            height: 1px;
            background-color: $trait;
        }
        a{
            position: relative;
            display: block;
            margin-top: 26px;
            line-height: 1.5em;
            font-size: 1em;
            font-weight: 700;
            margin-left: 22px;
			&:hover {
				color: $texte;
			}
            span{
                color: $orange;
                font-weight: 400;
				font-size: .9em;
            }
            &:hover:before {
                width: 75px;
                transform: translate(10px);
            }
            &:hover:after {
                right: 12px;
            }
            &:before{
                content: '';
                position: absolute;
                right: 21px;
                top: 20.5px;
                height: 2px;
                width: 38px;
                background-color:$orange;
                display: inline-block;
                transform: translate(0px);
            	transition: transform 0.5s, width 0.5s;
            }
            &:after{
                content:"";
                position: absolute;
                right: 23px;
                top: 12px;
                display: block;
                width: 15px;
                height: 15px;
                border-top: 2px solid transparent;
                border-right: 2px solid $orange;
                border-bottom: 2px solid $orange;
                border-left: 2px solid transparent;
                transform: rotate(-45deg);
            	transition: right 0.5s;
            }
        }
    }
}

/*--------------------------------------------------------------
-    Carte
--------------------------------------------------------------*/
#contact{
    .cont{
        position: relative;
        flex-basis: 100%;
        #carte{
            height: 482px;
            flex-basis: 100%;
            margin: 120px calc((100vw - 100%) / 2 * -1) 0;
            @include tablet{
                margin: 0 -20px;
            }
            @include mobileL{
                display: none;
            }
        }
    }
}

/*--------------------------------------------------------------
-    Addresse
--------------------------------------------------------------*/
#contact{
    .addresses{
        position: absolute;
        top: 54px;
        right: 0;
        @include cols(2);
        padding: 12px $gap;
        background-color: #fff;
        box-sizing: border-box;
        z-index: 1;
        @include laptop{
            @include cols(3);
            align-self: flex-start;
        }
        @include tablet{
            @include cols(4);
            top: -240px;
        }
        @include mobileL{
            position: relative;
            top: 0;
            left: 50%;
            width: calc(100% - #{$gap * 2});
            transform: translateX(-50%);
        }
        h3{
            margin-bottom: 10px;
            font-size: 2.15em;
            font-family: kelson;
            font-weight: 500;
        }
        hr{
            height: 1px;
            border: none;
            background-color: $trait;
        }
        a{
            display: block;
            margin-top: 26px;
            margin-bottom: 16px;
            font-size: 1.3em;
            font-weight: 700;
            img{
                margin-right: 13px;
            }
        }
        p{
            margin-bottom: 16px;
            span{
                font-weight: 700;
            }
            .dev {
                &::before{
    				content: "Afficher le numéro";
    				position: absolute;
    				margin: 5px 0 10px;
    				padding: 0px 20px 0px 20px;
    				background-color: $orange;
    				color: white;
    				font-size: 1em;
    				font-family: arial;
    				font-weight: bold;
                    cursor: pointer;
    			}
            }
        }
    }
}

/*--------------------------------------------------------------
-    Conseils
--------------------------------------------------------------*/
#contact{
	.conseils_transac {
		.conseil{
            margin: 0;
			line-height: 1.7;
			@include tablet{
				margin-bottom: 30px;
			}
		}
		@include tablet{
			margin: 15px 0;
		}
		@include mobileL{
			margin-top: -60px;
		}
	}
    // .conseils{
    //     .conseil{
    //         margin: 0;
    //         box-shadow: none;
    //     }
	// 	@include cols(6);
    //     @include tablet{
    //         margin: 15px 0;
    //         width: auto;
    //         max-width: none;
    //         align-self: stretch;
    //         box-shadow: none;
    //     }
    //     @include mobileL{
    //         margin-top: -60px;
    //     }
    // }
}



/*--------------------------------------------------------------
-    Derniére transaction
--------------------------------------------------------------*/
#contact{
    .recommandation{
        @include cols(3);
        margin: 0;
        padding-bottom: $gap;
        @include mobileL{
            padding: 0;
        }
        .h2like{
			margin: 0;
			padding: 20px;
			background-color: #fff;
			font-size: .8em;
		}
        figure{
            position: relative;
            overflow: hidden;
            margin-left: 0;
            margin-right: 0;
            height: 0;
            padding-bottom: 55%;
            margin-bottom: 15px;
            img{
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: .3s;
                backface-visibility: hidden;
            }
        }
		a {
            p {

            }
    		&:hover {
    			color: $texte;
    		}
        }
        @include tablet{
            width: auto;
            max-width: none;
            align-self: stretch;
        }
    }
}
