/*--------------------------------------------------------------
LISTE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Lot
- Big Link
- Img
- Desc
- Details
- Share
--------------------------------------------------------------*/

/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#results {
	max-width: 1240px;
	min-height: 1000px;
	margin: auto;
	.go-to-page-lot {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust as needed */
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #007bff; /* Spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/*--------------------------------------------------------------
-    Lot
--------------------------------------------------------------*/
#results > li {
	position: relative;
	opacity: 0;
	transform: translateY(-20px);
	transition: {
		property: opacity, transform;
		duration: 0.2s;
	}
	&.show {
		opacity: 1;
		transform: none;
	}
	&.hide {
		transform: translateY(20px);
	}
}

article.lot {
	display: flex;
	position: relative;
	margin-bottom: $gap;
	padding: $gap;
	background: #fff;
	box-shadow: 0 0 10px transparent;
	overflow: hidden;
	transition: box-shadow 0.3s;
	&:hover {
		z-index: 1;
		box-shadow: 0 0 15px $trait;
	}
	@include mobileL {
		flex-direction: column;
		padding: $gap_mob;
	}
	hr {
		margin: 0 $gap;
		width: 1px;
		align-self: stretch;
		border: none;
		background: $trait;
	}
}

/*--------------------------------------------------------------
-	Big Link
--------------------------------------------------------------*/
#results {
	.big_link {
		display: flex;
		flex: 1 1;
		&:hover {
			color: inherit;
		}
		@include mobileL {
			flex-direction: column;
		}
	}
}

/*--------------------------------------------------------------
-    Img
--------------------------------------------------------------*/
#results {
	figure.img_lot {
		position: relative;
		flex: 0 0 280px;
		max-height: 180px;
		margin-right: $gap;
		overflow: hidden;
		background: url(/img/annonce-defaut.jpg) center / cover;
		transition: 0.2s;
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 0.2s;
		}
	}
	li:hover {
		.img_lot {
			img {
				transform: scale(1.1);
			}
		}
	}
}

/*--------------------------------------------------------------
-    Desc
--------------------------------------------------------------*/
#results {
	.desc {
		flex: 1.8 1;
		p {
			margin-top: 10px;
			@include mobileL {
				display: none;
			}
		}
		.btn_surface,
		.btn_prix {
			margin: 0 5px 5px 0;
		}
	}
	h2,
	.h2_like {
		margin-bottom: 10px;
		font-weight: bold;
		font-size: 1.3em;
		@include mobileL {
			margin-top: $gap_mob;
		}
		span {
			font-weight: normal;
		}
	}
	strong {
		position: relative;
		font-weight: bold;
	}

	.details strong {
		top: 4px;
	}
	.tablet-loca {
		display: none;
		@include laptop {
			display: block;
			margin-top: $gap_mob;
		}
		svg {
			align-self: center;
			margin-bottom: -4px;
		}
	}
}

/*--------------------------------------------------------------
-    Details
--------------------------------------------------------------*/
#results {
	.details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 0 0 200px;
		min-width: 280px;
		@include laptop {
			display: none;
		}
		@include mobileL {
			display: block;
			flex: auto;
		}
	}
	.map {
		svg,
		img,
		br,
		figcaption {
			@include laptop {
				display: none;
			}
		}
	}
	figure {
		position: relative;
		white-space: nowrap;
		@include mobileL {
			margin: $gap_mob 0 $gap_mob 0;
		}
		svg {
			position: absolute;
			top: 0;
			bottom: 7px;
			left: 22px;
			margin: auto;
		}
	}
	.commercial {
		@include laptop {
			display: none;
		}
	}
	img,
	figcaption {
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
		line-height: 1.3;
	}
	.tel {
		display: block;
		margin-top: 10px;
		color: $orange;
		font-weight: bold;
	}
	.dev {
		&::before {
			content: 'Afficher le numéro';
			position: absolute;
			padding: 2px 10px 2px 10px;
			background-color: $orange;
			color: white;
			font-size: 0.7em;
			font-family: arial;
			font-weight: bold;
			cursor: pointer;
		}
	}
	.ref {
		align-self: flex-start;
		line-height: 33px;
		white-space: nowrap;
		border: 1px solid $trait;
		@include laptop {
			display: none;
		}
		@include mobileL {
			display: inline-block;
			max-width: 160px;
		}
		@include mobileS {
			display: block;
		}
		span {
			display: inline-block;
			margin-right: 10px;
			padding: 0 10px;
			font-weight: bold;
			background: $trait;
		}
	}
	.btn {
		display: none;
		position: relative;
		margin: $gap_mob;
		margin-right: 0;
		padding: 0;
		width: 40px;
		height: 40px;
		stroke: $trait;
		border: 1px solid $trait;
		border-radius: 50%;
		@include mobileL {
			display: inline-block;
			float: right;
			margin: 0 0 0 $gap_mob;
			top: -5px;
		}
		@include mobileM {
			margin: $gap_mob * 0.5 0 0 $gap_mob * 0.5;
			display: block;
		}
		@include mobileS {
			margin: $gap_mob 0 0 $gap_mob;
		}
		svg {
			@include abs(1);
		}
	}
}

/*--------------------------------------------------------------
-    Share
--------------------------------------------------------------*/
#results {
	.share {
		display: flex;
		margin-left: -10px;
		flex: 0 0 30px;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		color: $trait;
		@include laptop {
			display: none;
		}
		svg {
			fill: currentColor;
			stroke: currentColor;
		}
		a {
			margin: -10px -10px -10px -10px;
			border: 10px solid transparent;
			opacity: 0;
			transform: translateX(50px);
			transition: 0.2s, color 0.2s;
			@for $i from 2 through 4 {
				&:nth-child(#{$i}) {
					transition-delay: ($i - 1)/20 + 0s, 0s;
				}
			}
			&:hover {
				color: $orange;
			}
		}
	}
	hr {
		@include laptop {
			display: none;
		}
	}
	.hr_share {
		// opacity: 0;
		transition: 0.2s;
		transform: scaleY(0);
		transform-origin: 0 0;
		@include laptop {
			display: none;
		}
		@include mobileL {
			display: block;
		}
	}
	& .lot:hover {
		.hr_share,
		a {
			opacity: 1;
			transform: none;
		}
	}
}
