/*--------------------------------------------------------------
REFERENCE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
.ref{
	padding-right: 15px;
	line-height: 33px;
	border: 1px solid $trait;
	span{
		display: inline-block;
		margin-right: 10px;
		padding: 0 10px;
		font-weight: bold;
		background: $trait;
	}
}
