/*--------------------------------------------------------------
MOSAÏQUE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Img
- Txt
- Biens similaires
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
.mosaique{
	max-width: $layoutW;
	margin: auto;
	padding: 0 $gap;
	text-align: center;
	@include mobileL{
		padding: 0 $gap_mob;
	}
	ul{
		display: flex;
		position: relative;
		justify-content: center;
		min-height: 480px;
		text-align: left;
		@include tablet{
			flex-direction: column;
		}
		&:not(.loaded)::after{
			content: '';
			@include abs(1);
			width: 50px;
			height: 50px;
			border: 2px solid $trait;
			border-top-color: transparent;
			border-bottom-color: transparent;
			border-radius: 50%;
			animation: spin linear 1s infinite;
			@include tablet{
				display: none;
			}
		}
	}
	li{
		display: flex;
		position: relative;
		flex-basis: 33%;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-right: $gap;
		background: #fff;
		transition: .3s;
		animation: fadeIn .5s .5s backwards;
		@include tablet{
			margin: 0 0 $gap;
		}
		@include mobileL{
			margin-bottom: $gap_mob;
		}
		&:hover{
			box-shadow: 0 3px 15px #ccc;
		}
		&:last-child{
			margin: 0;
		}
	}
	ul a{
		display: flex;
		width: 100%;
		flex-direction: column;
		padding: 0 $gap;
		color: inherit;
		@include tablet{
			padding: $gap_mob $gap_mob 0 $gap_mob;
		}
	}
}


/*--------------------------------------------------------------
-	Img
--------------------------------------------------------------*/
.mosaique{
	figure{
		position: relative;
		margin: 0 $gap*-1 15px;
		overflow: hidden;
		background: $scamac;
		&::after{
			content: '';
			display: block;
			height: 0;
			padding-bottom: 55%;
		}
		@include tablet{
			margin: 0 0 $gap_mob;
		}
	}
	img{
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: .3s;
		backface-visibility: hidden;
	}
	li:hover{
		img{
			opacity: .8;
			transform: scale(1.1);
		}
	}
}



/*--------------------------------------------------------------
-	Txt
--------------------------------------------------------------*/
.mosaique{
	h3, .h3_like{
		color: $orange;
		font-size: 1.3em;
		text-transform: uppercase;
		strong{
			color: $texte;
			font-weight: bold;
			text-transform: none;
		}
	}
	.desc{
		margin-bottom: auto;
		@include mobileL{
			display: none;
		}
	}
	hr{
		margin: $gap/2 0;
		border: none;
		height: 1px;
		background: $trait;
		#home &{
			@include mobileL{
				display: none;
			}
		}
	}
	.adresse{
		margin-bottom: 10px;
		font-weight: bold;
		@include mobileL{
			margin-top: $gap_mob;
		}
	}
	svg{
		vertical-align: -4px;
		fill: currentColor;
	}
	.btns{
		display: flex;
		flex-wrap: wrap;
		padding-bottom: $gap;
		@include mobileL{
			padding-bottom: $gap_mob;
		}
	}
	.btn_surface, .btn_prix{
		margin-top: 5px;
		@include mobileL{
			order: 1;
			align-self: flex-start;
			padding: 0 15px;
		}
	}
	.btn{
		display: none;
		position: relative;
		margin: 0 $gap_mob $gap_mob;
		padding: 0;
		width: 40px;
		height: 40px;
		stroke: $trait;
		border: 1px solid $trait;
		border-radius: 50%;
		@include tablet{
			display: inline-flex;
			&:last-child {
				margin-left: 0;
			}
		}
		svg{
			@include abs(1);
		}
	}
}



/*--------------------------------------------------------------
-	Biens similaires
--------------------------------------------------------------*/
.biens-similaires{
	display: inline-block;
	position: relative;
	margin-top: 30px;
	padding: 20px;
	color: $orange;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
	&::after{
		content: '';
		position: absolute;
		left: 20px;
		right: 20px;
		bottom: 10px;
		height: 1px;
		background: $orange;
		transition: .2s;
	}
	&:hover{
		&::after{
			transform: scale(.1, 2);
		}
	}
}
