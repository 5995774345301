@import 'global';
@import 'header';
@import 'share';
@import 'resume/global';
@import 'resume/galerie';
@import 'resume/desc';
@import 'resume/map';
@import 'resume/dpe';
@import 'resume/commerciaux';
@import 'details/global';
@import 'details/superficies';
@media print {
    @import 'print';
}
