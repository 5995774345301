/*--------------------------------------------------------------
ANIMATIONS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Fade
- Scale
- Slide
- Spin
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Fade
--------------------------------------------------------------*/
@keyframes fadeIn {
	0%{
		opacity: 0;
		visibility: hidden;
	}
}
@keyframes fadeOut {
	100%{
		opacity: 0;
		visibility: hidden;
	}
}
@keyframes textFadeIn {
	0%{
		color: transparent;
	}
}



/*--------------------------------------------------------------
-	Scale
--------------------------------------------------------------*/
@keyframes scaleY {
	0%{
		transform: scaleY(0);
	}
}
@keyframes scaleX {
	0%{
		transform: scaleX(0);
	}
}



/*--------------------------------------------------------------
-    Slide
--------------------------------------------------------------*/
@keyframes slideIn {
	0%{
        opacity: 0;
		transform: translateY(-20px);
	}
}

@keyframes slideOut {
	100%{
        opacity: 1;
		transform: translateY(0px);
	}
}

/*--------------------------------------------------------------
-	Spin
--------------------------------------------------------------*/
@keyframes spin {
	100%{
		transform: rotate(360deg);
	}
}