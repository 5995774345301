$search_height : 47px;
$search_height_mob : 62px;

#recherche.main {
	padding-bottom: 0;
}


#search_result {
	position: fixed;
	top: $headerH;
	left: 0;
	right: 0;
	padding: 0 $gap;
	z-index: 3;
	@include tablet{
		display: none;
		padding: 0;
		top: $headerH + 81px;
	}
	@include mobileL{
		top: $headerH_mob + $search_height_mob;
	}
	&::before{
		content: '';
		@include abs(0, bottom);
		height: $search_height;
		background-color: $calque;
		z-index: -1;
	}
	&::after{
		content: '';
		@include fix;
		background: rgba(#000, .5);
		opacity: 0;
		transition: opacity .2s;
		pointer-events: none;
		z-index: -2;
		@include tablet{
			opacity: 1;
		}
	}
	&:hover{
		&::after{
			opacity: 1;
		}
	}
	#search_tabs {
		height: $search_height;
		max-width: $layoutW;
		margin: auto;
		li{
			display: inline-block;
			position: relative;
			max-width: 200px;
			height: $search_height;
			line-height: $search_height;
			text-align: center;
			background-color: $scamac;
			@include laptop{
				font-size: 0.8em;
			}
			@include tablet {
				display: block;
				max-width: none;
				width: 100%;
				height: $search_height_mob;
				line-height: $search_height_mob;
			}
			&:before {
				content: '';
				display: none;
				width: 95%;
				height: 1px;
				margin: 0 auto;
				background-color: rgba(255, 255, 255, 0.4);
				@include tablet{
					display: block;
				}
			}

			label {
				display: block;
				position: relative;
				color: #fff;
				font-size: 1em;
				font-family: Arial;
				font-weight: 400;
				// max-width: 150px;
				height: $search_height;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding: 0 20px 0 30px;

				&::before{
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 10px;
					margin: auto;
					width: 8px;
					height: 8px;
					border: 2px solid $orange;
					border-left: none;
					border-bottom: none;
					transition: transform .1s;
					transform: rotate(45deg);
				}
				&::after{
					content: '';
					@include abs(0, left);
					width: 4px;
					z-index: 1;
					background-color: $calque;
					@include tablet{
						display: none;
					}
				}

				@include tablet {
					font-size: 1.5em;
					font-weight: 700;
					height: $search_height_mob;
				}
			}
			input {
				position: absolute;
				opacity: 0;
				&:checked + label {
					color: $scamac;
					background: #fff;
					&::before{
						transform: translateY(-3px) rotate(135deg);
					}
				}
			}
		}
		.cloche{
			display: inline-block;
			&::before{
				display: none;
			}
			label{
				padding-left: 15px;
				background-color: $orange;
				&::before{
					display: none;
				}
				svg{
					vertical-align: middle;
					fill: #fff;
					margin-right: 10px;
					animation: wiggle 5s infinite;
					transform-origin: top;
				}
				@keyframes wiggle {
					0% { transform: rotate(0deg); }
					80% { transform: rotate(0deg); }
					85% { transform: rotate(20deg); }
					86% { transform: rotate(-20deg); }
					89% { transform: rotate(20deg); }
					90% { transform: rotate(-20deg); }
					91% { transform: rotate(20deg); }
					92% { transform: rotate(-20deg); }
					95% { transform: rotate(20deg); }
					100% { transform: rotate(0deg); }
				}
			}
		}
	}
	.submit{
		background-color: $orange;
		color: #fff;
	}
	.types{
		text-align: center;
		margin-bottom: 20px;
	}
}



/*--------------------------------------------------------------
Fieldset
--------------------------------------------------------------*/
#search_result>fieldset{
	display: none;
	position: relative;
	padding: $gap;
	max-width: $layoutW;
	margin: auto;
	color: $scamac;
	background: #fff;
	box-sizing: border-box;
	&.on{
		display: block;
		box-shadow: 0 2px 9px rgba(#000, .2);
	}
	@include tablet {
		position: fixed;
		width: 100%;
		margin-top: 0;
	}
}



/*--------------------------------------------------------------
Labels / Inputs
--------------------------------------------------------------*/
#search_result fieldset{
	// width: 1160px;
	label{
		display: inline-block;
		position: relative;
		margin: $gap/2;
		margin-left: 0;
		padding: 0 $gap*1.5 0 $gap/2;
		line-height: $btnH;
		font-weight: bold;
		border: 1px solid $trait;
		box-sizing: border-box;
		& > span{
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			padding: 0 $gap/2;
			right: 0;
		}
		&.disabled {
			background-color: silver;
			cursor: not-allowed;
			input {
				cursor: not-allowed;
				background-color: silver;
			}
		}
	}
	input{
		height: 100%;
		width: 100%;
		font-weight: normal;
		border: none;
		box-sizing: border-box;
		&::placeholder{
			color: $placeholder;
		}
	}
}



/*--------------------------------------------------------------
Types de biens
--------------------------------------------------------------*/
#search_result .types{
	width: 80%;
	margin: 0 auto;
	li{
		display: inline-block;
	}
	label{
		margin-top: 0;
		padding: 0 25px 0 10px;
		color: $placeholder;
		border-radius: $radius;
		user-select: none;
		transition: .2s;
		&:after{
			@include pseudo;
			position: absolute;
			top: 0;
			bottom: 5px;
			right: 10px;
			width: 4px;
			margin: auto;
			height: 8px;
			border: 2px solid $orange;
			border-left: none;
			border-top: none;
			transform: rotate(45deg);
			transition: .1s;
			opacity: 0;
			transform: translateY(10px) rotate(45deg);
		}
	}
	input{
		position: absolute;
		visibility: hidden;
		&:checked + label{
			color: inherit;
			&:after{
				opacity: 1;
				transform: rotate(45deg);
			}
		}
	}
	.off{
		display: none;
	}
}

/*--------------------------------------------------------------
Plus de critères
--------------------------------------------------------------*/
#search_result #more_criteria{
	display: inline-block;
	position: relative;
	margin: 10px 0 0;
	padding: 0;
	width: 14px;
	height: 14px;
	&:before, &:after{
		@include pseudo;
		@include abs(1, right);
		border: 1.5px solid currentColor;
	}
	&:before{
		width: 100%;
		height: 0;
	}
	&:after{
		left: 7px;
		height: 100%;
		width: 0;
	}
	&.on{
		display: none;
	}
}
/*--------------------------------------------------------------
Submit
--------------------------------------------------------------*/
#search_result .submit{
	display: block;
	margin: $gap*1.5 auto;
	padding: 0 $gap;
	height: $gap*2.5;
	font-weight: bold;
	color: #fff;
	border: 2px solid $orange;
	border-radius: $radius;
	box-shadow: 0 4px 7px rgba($orange, .1);
	&:before{
		content: '';
		display: inline-block;
		margin-right: $gap/2;
		width: 0;
		height: 0;
		border: 4px solid transparent;
		border-left: 7px solid $scamac;
		border-right: none;
	}
}
/*--------------------------------------------------------------
Référence
--------------------------------------------------------------*/
#search_result .ref{
	position: absolute;
	margin: 0;
	padding: 0;
	height: auto;
	bottom: $gap;
	right: $gap;
	color: $orange;
	border: none;
	input{
		height: 30px;
		width: 170px;
		border-bottom: 1px solid $trait;
		text-transform: uppercase;
		&::placeholder{
			color: currentColor;
			text-transform: none;
		}
	}
	svg{
		margin-left: $gap/2;
		vertical-align: middle;
		stroke: currentColor;
	}
}
/*--------------------------------------------------------------
Localisation
--------------------------------------------------------------*/
#search_result .place{
	text-align: center;
	position: relative;
	height: $btnH + $gap;
	width: 100%;
	label{
		&:nth-of-type(1){
			width: 50%;
			padding: 0 $gap/2 0 $gap*1.5;
		}
		&:nth-of-type(2){
			margin-left: 10px;
			right: 0;
			width: 60px;
		}
	}
	svg{
		@include abs(1, right);
		margin-left: $gap/2;
		vertical-align: -3px;
	}
}

/*--------------------------------------------------------------
Zone personnalisée
--------------------------------------------------------------*/
#search_result .zone{
	@include button($bleu);
	margin-top: $gap/2;
	box-shadow: 0 2px 3px rgba(#000, .2);
	svg{
		margin-right: $gap/2;
		stroke: currentColor;
		vertical-align: -4px;
	}
}

/*--------------------------------------------------------------
Surface
--------------------------------------------------------------*/
#search_result .surface{
	text-align: center;
	label{
		width: 35%;
	}
}
#search_result .surface input{
	width: 200px;
	text-align: center;
}
#search_result #cities {
	text-align: center;

	.city {
		background-color: $trait;
		border-radius: 5px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		display: inline-block;
		min-width: 100px;
		position: relative;
		margin: 5px;
		padding-right: 30px;
		padding-left: 10px;

		span.delete {
			cursor: pointer;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			height: 10px;
			width: 10px;
			display: inline-block;
			background : {
				image : url('/img/pictos/cross.svg');
				repeat : no-repeat;
				size : contain;
			};
		}
	}
}


/*--------------------------------------------------------------
Recherche mobile
--------------------------------------------------------------*/
#mobile-search-close {
	display: none;
	position: fixed;
	top: $headerH;
	left: 0;
	right: 0;
	height: $search_height;
	background-color: $scamac;
	@include tablet {
		display: block;
		height: 81px;
	}
	@include mobileL{
		top: $headerH_mob;
		height: $search_height_mob;
	}

	button {
		background-color: $orange;
		height: 40px;
		width: 40px;
		position: absolute;
		left: 2.5%;
		top: 50%;
		transform: translateY(-50%);

		span {
			background-color: white;
			height: 2px;
			width: 20px;
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;

			&:nth-child(1) {
				transform: translate(-50%, -50%) rotateZ(45deg);
			}
			&:nth-child(2) {
				transform: translate(-50%, -50%) rotateZ(-45deg);
			}
		}
	}

}
