/*--------------------------------------------------------------
HOME -> GLOBAL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Conseils et dernière transac
- Conseils
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#home{
	padding: $headerH 0;
	max-width: none;
	@include mobileL{
		padding-top: $headerH_mob;
	}
}



/*--------------------------------------------------------------
-	Conseils et dernière transac
--------------------------------------------------------------*/
.conseils_transac{
	display: flex;
	max-width: $layoutW;
	margin: 60px auto 0;
	align-items: flex-start;
	justify-content: space-between;
	@include cols(9);
	@include tablet{
		display: block;
		width: auto;
		max-width: none;
	}
	.conseil{
		@include cols(6);
		box-sizing: border-box;
		@include tablet{
			margin: 0 $gap;
			width: auto;
			max-width: none;
			box-shadow: none;
		}
		@include mobileL{
			margin: 0 $gap_mob;
		}
		.wrapper-texte-nos-conseils{
			p {
				line-height: 1.7;
			}
		}
	}
	.last_transaction{
		@include cols(3);
		@include tablet{
			width: auto;
			max-width: none;
		}
	}
}
