/*--------------------------------------------------------------
LOT > RESUME > MAP  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Adresse
- Map
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-    Adresse
--------------------------------------------------------------*/
#zoom_lot{
	.adresse{
		flex-basis: 100%;
		font-weight: bold;
		@include tablet{
			padding: $gap;
			background: #fff;
		}
		@include mobileL{
			padding: $gap_mob;
		}
		svg{
			margin-right: 5px;
			vertical-align: -3px;
		}
	}
}



/*--------------------------------------------------------------
-    Map
--------------------------------------------------------------*/
#map_city{
    position: relative;
    margin-top: 15px;
    height: 0;
    padding-bottom: 56%;
    iframe{
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
