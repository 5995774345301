/*--------------------------------------------------------------
LOT > RESUME > GLOBAL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#zoom_lot{
	.resume{
		@include cols(4);
		@include tablet{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: auto;
			max-width: none;
		}
		@include mobileL{
			display: block;
		}
		section{
			margin-bottom: 30px;
			@include tablet{
				margin-bottom: $gap;
			}
			@include mobileL{
				margin-bottom: $gap_mob;
			}
		}
	}
}
