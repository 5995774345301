/*--------------------------------------------------------------
TITLE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Citation
- Liste
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-    Global
--------------------------------------------------------------*/
#conseils{
    display: flex;
    max-width: $layoutW;
    margin: 0 auto 60px;
    padding: $headerH $gap 0;
    justify-content: space-between;
    @include laptop{
		display: block;
    }
    @include mobileL{
        padding: $headerH_mob $gap_mob 0;
    }
    .main_col{
        @include cols(6);
        @include laptop{
    			width: auto;
    			max-width: none;
    			margin-bottom: 20px;
        }
    }
    .aside{
        margin-top: $gap;
        @include cols(3);
        @include laptop{
            width: auto;
            max-width: none;
        }
    }

}



/*--------------------------------------------------------------
-    Citation
--------------------------------------------------------------*/
.citation_conseils{
    position: relative;
    padding: 70px;
    background: $calque;
	transform: translate(-100px);
	opacity: 0;
	transition: transform 0.5s, opacity 0.5s;
	&.on {
		opacity: 1;
		transform: translate(0px);
	}
    @include tablet{
        padding: 50px;
    }
    @include mobileL{
        padding:  30px;
    }
    &::before{
        content: '';
        @include abs(0, left);
        left: -100vw;
        background: $calque;
        z-index: -1;
        @include laptop{
            right: $gap*-1;
        }
        @include mobileL{
            right: $gap_mob*-1;
        }
    }
    p{
        line-height: 1.5;
        font-size: 1.4em;
        font-style: italic;
        text-align: center;
        @include mobileL{
            font-size: 1.2em;
        }
        &::before, &::after{
            content: "«";
            position: relative;
            margin: 0 8px;
            color: $orange;
            line-height: 0;
            font-weight: bold;
            font-style: normal;
        }
        &::before{
            top: -10px;
            font-size: 5.5em;
            vertical-align: top;
            @include mobileL{
                font-size: 2.75em;
                top: 0px;
            }
        }
        &:after{
            content: "»";
            vertical-align: middle;
            font-size: 2.5em;
            @include mobileL{
                font-size: 1.75em;
            }
        }
    }
}



/*--------------------------------------------------------------
-    Liste
--------------------------------------------------------------*/
.liste_conseils{
    li{
        margin-top: 60px;
        transform: translateY(100px);
    	opacity: 0;
    	transition: transform 0.5s, opacity 0.5s;
    	&.on {
    		opacity: 1;
    		transform: translateY(0px);
    	}
        @include mobileL{
            margin-top: 100px;
            margin-bottom: 100px;
        }
    }
    article{
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-between;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            @include cols(5);
            background: #fff;
            z-index: -1;
            @include laptop{
                @include cols(7);
            }
            @include mobileL{
                width: 100%;
				max-width: none;
            }
        }
    }
    figure{
        margin-top: -30px;
        transform: translate(-100px);
    	opacity: 0;
    	transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
    	&.on {
    		opacity: 1;
    		transform: translate(0px);
    	}
        @include cols(3);
        @include laptop{
            @include cols(4);
        }
        @include mobileL{
            margin: auto;
            margin-top: -60px;
            width: 80%;
            max-width: none;
        }
    }
    img{
        width: 100%;
    }
    .text{
        transform: translateY(50px);
    	opacity: 0;
    	transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
    	&.on {
    		opacity: 1;
    		transform: translateY(0px);
    	}
        @include cols(3);
        padding: $gap $gap $gap 0;
        box-sizing: border-box;
        @include laptop{
            @include cols(5);
        }
        @include mobileL{
            padding: $gap_mob*1.5 $gap_mob $gap_mob $gap_mob;
            width: auto;
            max-width: none;
        }
    }
    h2{
        margin-bottom: 30px;
        font-size: 2.1em;
        color: $scamac;
        @include kelson;
        @include tablet{
            font-size: 25px;
        }
        @include mobileL{
            margin-bottom: $gap_mob;
        }
    }
    .see_more_wrap{
        transform: translate(-50px);
    	opacity: 0;
    	transition: transform 0.5s 0.3s, opacity 0.5s 0.3s;
    	&.on {
    		opacity: 1;
    		transform: translate(0px);
    	}
        &::before{
            content: '';
            display: inline-block;
            @include cols(1);
            height: 10px;
            @include laptop{
                @include cols(4);
            }
            @include mobileL{
                width: auto;
                max-width: none;
            }
        }
    }
    .see_more{
        display: inline-block;
        margin: 0 0 10px $gap*2;
        padding: 20px;
        color: $orange;
        font-size: 11px;
        font-weight: 700;
        letter-spacing: .1em;
        text-transform: uppercase;
        @include laptop{
            margin: 0;
        }
        @include mobileL{
            padding: 0 0 $gap_mob*2 $gap_mob;
        }
        &:after{
            @include pseudo;
            margin-top: 6px;
            height: 1px;
            background: $orange;
            transition: .2s;
            transform-origin: 100% 0;
        }
        &:hover{
            &:after{
                transform: translateX(10px) scale(.2, 2);
            }
        }

    }
}
