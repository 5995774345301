/*--------------------------------------------------------------
TITLE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Definitions
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-    Global
--------------------------------------------------------------*/
#dico{
    display: flex;
    max-width: $layoutW;
    margin: 0 auto 60px;
    padding: $headerH $gap 0;
    justify-content: space-between;
    @include laptop{
		display: block;
    }
    @include mobileL{
        padding: $headerH_mob $gap_mob 0;
    }
    .main_col{
        @include cols(6);
        @include laptop{
            width: auto;
            max-width: none;
            margin-bottom: 20px;
        }
    }
    .aside{
        margin-top: $gap;
        @include cols(3);
        @include laptop{
            margin-top: 50px;
            width: auto;
            max-width: none;
        }
    }
}



/*--------------------------------------------------------------
-    Definitions
--------------------------------------------------------------*/
#definitions{
    dt{
        margin-top: $gap;
        padding: $gap $gap 0;
        font-size: 2em;
        line-height: 1.5;
        @include kelson;
        background: #fff;
        @include mobileL{
            margin-top: $gap_mob;
            padding: $gap_mob $gap_mob 0;
            font-size: 1.5em;
        }
    }
    dd{
        padding: 15px $gap 25px;
        background: #fff;
        @include mobileL{
            padding: 10px $gap_mob 20px;
        }
    }
}
