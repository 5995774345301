/*--------------------------------------------------------------
Qui-Sommes-Nous  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Ressource
- Devanture
- Stratégie
- Solution
- Data
- Equipe
- Temoignage
- On parle de nous
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#qsn{
	justify-content: space-between;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0 0 0 100vw #fff;
	max-width: none;
	padding: 80px 0px 60px;
	@include tablet{
		flex-direction: column;
	}
	.wrapper{
		max-width: $layoutW;
		margin: auto;
		padding: 0;
	}
	h1 {
		padding-bottom:$gap;
		color: $texte;
		font-family: Kelson;
		font-size: 2.85em;
		font-weight: 300;
		span {
			font-weight: 500;
		}
		@include tablet{
			font-size: 2.1em
		}
		@include mobileL{
			font-size: 1.9em;
		}
	}
	.viewArticles{
		width: 200px;
		margin: 65px auto;
		padding: 15px 0;
		text-align: center;
		border: 1px solid #eb7625;
		border-radius: 4px;
		box-shadow: 0 4px 7px rgba(255,100,0,0.1);
	}
}


/*--------------------------------------------------------------
-	Ressource
--------------------------------------------------------------*/
#qsn{
	.contener{
		display: flex;
		justify-content: space-between;
		padding: 0 $gap;
		@include mobileL{
			flex-direction: column;
			padding: 0 $gap_mob;
		}
	}
	.ressource{
		@include cols(5);
		box-sizing: border-box;
		margin-top: $gap*2;
		@include mobileL{
			width: auto;
			max-width: none;
		}
		.ressource-h1 {
			transform: translate(-100px);
			opacity: 0;
			transition: transform 0.5s, opacity 0.5s;
			&.on {
				opacity: 1;
				transform: translate(0px);
			}
		}
		p {
			color: $texte;
			font-family: Arial;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			margin-bottom: 20px;
			max-width: 587px;
			span{
				font-weight: 700;
			}
			@include tablet{
				text-align: justify;
			}
		}
	}
	.ressources-texte {
		transform: translate(-100px);
		opacity: 0;
		transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
		&.on {
			opacity: 1;
			transform: translate(0px);
		}
	}
	.ressources-sous-texte {
		@include cols(3);
		padding: $gap;
		box-shadow: 0 0 10px rgba(182, 182, 182, 0.5);
		background-color: #ffffff;
		color: $texte;
		font-family: Arial;
		font-size: 14px;
		font-weight: 400;
		line-height: 26px;
		transform: translateY(100px);
		opacity: 0;
		transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
		&.on {
			opacity: 1;
			transform: translateY(0px);
		}
		@include laptop{
			@include cols(4);
		}
		@include tablet{
			text-align: justify;
		}
		@include mobileL{
			width: auto;
			max-width: none;
		}
		span {
			font-weight: 700;
		}
	}
}


/*--------------------------------------------------------------
-	Devanture
--------------------------------------------------------------*/
#qsn{
	#devanture {
		@include cols(4);
		margin-top: $gap*2;
		height: 557px;
		overflow: hidden;
		transform: translate(100px);
		opacity: 0;
		transition: transform 0.75s 0.3s, opacity 0.75s 0.3s;
		@include mobileL{
			margin-top: 0;
			height: auto;
			width: auto;
			max-width: none;
		}
		&.on {
			opacity: 1;
			transform: translate(0px);
		}
		img {
			height: 100%;
			@include mobileL{
				height: 246px;
				width: 100%;
			}
		}
	}
}

/*--------------------------------------------------------------
-	Stratégie
--------------------------------------------------------------*/

#qsn{
	.strategie {
		display: flex;
		flex-direction: column;
		section{
			@include cols(6);
			margin-top: $gap*4;
			padding-left: $gap;
			border-left: 1px solid #d5d5d5;
			align-self: flex-end;
			@include mobileL{
				margin-top:  $gap;
				width: auto;
				max-width: none;
				padding: 0 $gap_mob;
				border: none;
			}
		}
		h1 {
			transform: translate(-100px);
			opacity: 0;
			transition: transform 0.5s, opacity 0.5s;
			&.on {
				opacity: 1;
				transform: translate(0px);
			}
		}
		p {
			color: $texte;
			font-family: Arial;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			padding-bottom: $gap;
			transform: translate(-100px);
			opacity: 0;
			transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
			&.on {
				opacity: 1;
				transform: translate(0px);
			}
			span{
				font-weight: 700;
			}
		}
	}
}

/*--------------------------------------------------------------
-	Solution
--------------------------------------------------------------*/
#qsn{
	.solutions {
		text-align: center;
		z-index: 1;
		padding: 0 $gap_mob;
		flex-basis: 100%;
		transform: translateY(100px);
		opacity: 0;
		transition: transform 0.5s, opacity 0.5s;
		&.on {
			opacity: 1;
			transform: translateY(0px);
		}
		@include mobileL{
			background-color: #f8f8f8;
		}
		p{
			position: relative;
			left: 50%;
			color: #000000;
			font-family: Arial;
			font-size: 22px;
			font-weight: 400;
			line-height: 34px;
			max-width: 700px;
			transform: translateX(-50%);
			padding: 30px 0;
			@include mobileL{
				font-size: 16px;
			}
			span {
				font-weight: 700;
			}
		}
	}
}

/*--------------------------------------------------------------
-	Data
--------------------------------------------------------------*/
.data {
	display: grid;
	grid-template-columns: repeat(4, 25%);
	grid-template-rows: auto;
	grid-template-areas:
	". t t ."
	". b . ."
	". b . ."
	"a b d ."
	"a . d e"
	"a c d e"
	". c . e"
	". c . .";
	z-index: 3;
	@include tablet{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	@include mobileL{
		display:block;
		margin-bottom: 100px;
	}
	h2{
		grid-area: t;
		margin-top: 50px;
		margin-bottom: -40px;
		text-align: center;
		font-size: 1.4em;
		font-family: Kelson;
		color: #bbbbbb;
		letter-spacing: .45em;
		transform: translateY(50px);
		opacity: 0;
		transition: transform 0.5s, opacity 0.5s;
		&.on {
			opacity: 1;
			transform: translateY(0px);
		}
		@include tablet{
			flex-basis: 100%;
		}
	}
	p {
		margin-top: 35px;
		text-align: center;
		color: #eb7625;
		font-family: Kelson;
		font-size: 150px;
		font-weight: 400;
		line-height: 38px;
		@include laptop{
			font-size: 130px;
		}
		span {
			font-family: Arial;
			font-size: 26px;
			font-weight: 400;
			line-height: 30px;
			@include laptop{
				font-size: 22px;
			}
		}
	}
	.block1 {
		grid-area: a;
		height: auto;
		margin-top: 60px;
		border-left: 1px solid #d5d5d5;
		transform: translate(-100px);
		opacity: 0;
		transition: transform 0.5s, opacity 0.5s;
		&.on {
			opacity: 1;
			transform: translate(0px);
		}
		@include tablet{
			width: calc(34% - 30px);
		}
		@include mobileL{
			display: flex;
			flex-direction: column;
			max-width: none;
			width: auto;
		}
		p{
			margin-top: 80px;
			line-height: 34px;
			@include mobileL{
				margin-top: 50px;
				font-size: 100px;
				line-height: 26px;
			}
			@include mobileS{
				font-size: 90px;
			}
			span{
				line-height: 0;
				@include mobileL{
					margin-top: 50px;
					font-size: 16px;
				}
			}
		}
	}
	.block2 {
		margin-top: 80px;
		grid-area: b;
		background-color: $orange;
		height: 270px;
		text-align: center;
		transform: translate(-100px);
		opacity: 0;
		transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
		&.on {
			opacity: 1;
			transform: translate(0px);
		}
		@include laptop{
			height: 250px;
		}
		@include tablet{
			width: calc(36% - 30px);
		}
		@include mobileL{
			display: flex;
			margin-top: 40px;
			flex-direction: column;
			max-width: none;
			width: auto;
			height: auto;
			padding: 20px 0 30px;
		}
		p{
			margin-top: 80px;
			color: #ffffff;
			font-family: Kelson;
			font-size: 80px;
			font-weight: 400;
			line-height: 38px;
			@include laptop{
				font-size: 80px;
			}
			@include mobileL{
				margin-top: 50px;
				font-size: 60px;
				line-height: 27px;
			}
			@include mobileS{
				font-size: 50px;
			}
			span{
				line-height: 0;
				@include mobileL{
					font-size: 16px;
				}
			}
		}
	}
	.block3 {
		grid-area: c;
		height: 236px;
		border-left: 1px solid #d5d5d5;
		border-right: 1px solid #d5d5d5;
		margin-top: 82px;
		margin-bottom: 100px;
		text-align: center;
		transform: translate(-100px);
		opacity: 0;
		transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
		&.on {
			opacity: 1;
			transform: translate(0px);
		}
		@include tablet{
			width: calc(36% - 30px);
		}
		@include mobileL{
			display: flex;
			margin-top: 40px;
			margin-bottom: 40px;
			flex-direction: column;
			max-width: none;
			width: auto;
			height: auto;
		}
		.plus{
			position: relative;
			right: 10px;
			text-align: right;
			@include mobileL{
				line-height: 38px;
				margin-top: 10px;
				font-size: 80px
			}
			.de{
				position: relative;
				bottom: 25px;
				right: 17px;
				font-size: 40px;
				@include laptop{
					bottom: 20px;
				}
				@include mobileL{
					font-size: 30px;
					top: -21px;
					right: 14px;
				}
			}
		}
		P {
			font-size: 100px;
			@include laptop{
				font-size: 80px;
			}
			@include mobileL{
				margin-top: 24px;
				font-size: 60px;
				line-height: 24px;
			}
			@include mobileS{
				font-size: 50px;
			}
			span{
				line-height: 0;
				@include mobileL{
					font-size: 16px;
				}
			}
		}
	}
	.block4 {
		grid-area: d;
		height: 258px;
		margin-top: -40px;
		border-right: 1px solid #d5d5d5;
		transform: translate(-100px);
		opacity: 0;
		transition: transform 0.5s 0.3s, opacity 0.5s 0.3s;
		&.on {
			opacity: 1;
			transform: translate(0px);
		}
		@include tablet{
			width: calc(50% - 30px);
		}
		@include mobileL{
			display: flex;
			flex-direction: column;
			max-width: none;
			width: auto;
			height: auto;
			margin-top: 0;
		}
		p{
			margin-top: 80px;
			@include mobileL{
				position: relative;
				font-size: 100px;
				line-height: 26px;
				margin-top: 0;
			}
			@include mobileS{
				font-size: 90px;
				line-height: 24px;
			}
		}
		span{
			line-height: 0;
			@include mobileL{
				font-size: 16px;
			}
		}
	}
	.block5 {
		grid-area: e;
		height: 245px;
		border-right: 1px solid #d5d5d5;
		transform: translate(-100px);
		opacity: 0;
		transition: transform 0.5s 0.4s, opacity 0.5s 0.4s;
		&.on {
			opacity: 1;
			transform: translate(0px);
		}
		@include tablet{
			width: calc(50% - 30px);
		}
		@include mobileL{
			display: flex;
			margin-top: 40px;
			flex-direction: column;
			max-width: none;
			width: auto;
			height: auto;
		}
		.plus{
			position: relative;
			right: 50px;
			text-align: right;
			@include mobileL{
				font-size: 80px;
				right: 34px;
			}
			.de{
				position: relative;
				bottom: 25px;
				right: 17px;
				font-size: 40px;
				@include laptop{
					bottom: 20px;
				}
				@include mobileL{
					font-size: 30px;
					bottom: 21px;
				}
			}
		}
		P {
			font-size: 100px;
			@include laptop{
				font-size: 80px;
			}
			@include mobileL{
				font-size: 60px;
				line-height: 26px;
			}
			@include mobileS{
				line-height: 22px;
			}
			span{
				line-height: 0;
				@include mobileL{
					font-size: 16px;
				}
			}
		}
	}
	div{
		@include mobileL{
			border: none !important;
		}
		.plus{
			@include mobileL{
				right: auto !important;
				text-align: center !important;
			}
		}
		p:not(.plus){
			@include mobileL{
				margin-top: 10px;
				line-height: 1;
			}
			span{
				@include mobileL{
					display: block;
					margin-top: 15px !important;
					line-height: 1.5 !important;
				}
			}
		}
	}
}

/*--------------------------------------------------------------
-	Equipe
--------------------------------------------------------------*/
#qsn{
	.equipe {
		margin: 80px calc((100vw - 100%) / 2 * -1) 0;
		background: url('../../../img/duo.jpg') no-repeat top center;
		background-size: cover;
		height: 580px;
		z-index: 1;
		@include mobileL{
			margin: -$gap/1.5 calc((100vw - 100%) / 2 * -1) 0;
			height: 320px;
		}
		h2{
			position: relative;
			top: -50px;
			text-align: center;
			font-size: 1.4em;
			font-family: Kelson;
			color: #bbbbbb;
			letter-spacing: .45em;
			transform: translateY(50px);
			opacity: 0;
			transition: transform 0.5s, opacity 0.5s;
			&.on {
				opacity: 1;
				transform: translateY(0px);
			}
			@include mobileL{
				top: -40px;
				font-size: 1.1em;
			}
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
}

/*--------------------------------------------------------------
-	Temoignage
--------------------------------------------------------------*/
#qsn{
	.background-gris-qui-sommes-nous{
		background: #f8f8f8;
		overflow: hidden;
	}
	.clients {
		position: relative;
		background-color: #f8f8f8;
		height: 100%;
		z-index: 1;
		@include mobileL{
			margin-top: 0;
		}
		h2{
			position: relative;
			top: $gap;
			text-align: center;
			font-size: 1.4em;
			font-family: Kelson;
			color: #bbbbbb;
			letter-spacing: .45em;
			@include mobileL{
				top: 26px;
				font-size: 1.2em;
			}
		}
		#contener{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			@include laptop{
				justify-content: space-around;
			}
			@include tablet{
				justify-content: space-between;
			}
			@include mobileL{
				flex-wrap: nowrap;
				overflow: auto;
				width: 100%;
			}
		}
		.reveal {
			display: none;
		}
		.temoignage {
			position: relative;
			background-color: #fff;
			height: auto;
			margin-top: 78px;
			margin-bottom: 20px;
			padding-left: $gap;
			flex: 0 0 auto;
			width: calc(33% - 30px);
			transform: translate(100px);
			opacity: 0;
			transition: transform 0.5s , opacity 0.5s;
			&.on {
				opacity: 1;
				transform: translate(0px);
			}
			@include laptop{
				max-width: none;
				width: calc(48% - 30px);
			}
			@include tablet{
				width: calc(46% - -2px);
			}
			@include mobileL{
				margin-top: 90px;
				width: 100%;
				margin-right: $gap_mob;
				box-sizing: border-box;
			}
			img{
				display: inline-block;
				margin-top: -30px;
				margin-bottom: 30px;
				transform: translateY(50px);
				opacity: 0;
				transition: transform 0.5s 0.25s, opacity 0.5s 0.25s;
				&.on {
					opacity: 1;
					transform: translateY(0px);
				}
			}
			h3{
				position: absolute;
				left: 160px;
				right: 20px;
				top: 20px;
				font-size: 1em;
				font-weight: 700;
				line-height: 1.5em;
				font-family: Arial;
				@include mobileS{
					font-size: .84em;
				}
				span{
					color: $orange;
					font-weight: 400;
				}
			}
			h4{
				line-height: 1.6em;
				font-weight: 700;
				margin-bottom: 10px;
			}
			p{
				margin-bottom: 30px;
				max-width: 330px;
				line-height: 1.5em;
				span{
					position: relative;
					top: 8px;
					color: $orange;
					font-size: 4em;
				}
			}
			.link{
				color: $orange;
				font-size: .8em;
				font-weight: bold;
				letter-spacing: .1em;
				text-transform: uppercase;
				&:after{
					@include pseudo;
					margin-top: 6px;
					margin-bottom: 40px;
					height: 1px;
					background: $orange;
					transition: .2s;
					transform-origin: 100% 0;
					width: 26%;
				}
				&:hover{
					&:after{
						transform: translateX(10px) scale(.2, 2);
					}
				}
			}
		}
		.viewArticles{

			@include mobileL{
				display: none;
			}
		}
		// &:before{
		// 	display: block;
		// 	position: absolute;
		// 	top: 0;
		// 	right: calc((100vw - 100%)*-1);
		// 	bottom: 0;
		// 	left: calc((100vw - 100%)*-1);
		// 	background-color: #f8f8f8;
		// 	content: '';
		// }
	}
}

/*--------------------------------------------------------------
-	On parle de nous
--------------------------------------------------------------*/
#qsn{
	&:after{
		content:"";
		z-index: 0;
		background-color: #f8f8f8;
		align-items: stretch;
	}
	.nous{
		padding: 0 $gap;
		@include mobileL{
			padding: 0 $gap_mob;
		}
		ul {
			.reveal {
				display: none;
			}
		}
		.h2-home{
			margin-top: 40px;
			margin-bottom: 50px;
			text-align: center;
			font-size: 1.4em;
			font-family: Kelson;
			color: #bbbbbb;
			letter-spacing: .45em;
			text-transform: uppercase;
			transform: translateY(50px);
			opacity: 0;
			transition: transform 0.5s, opacity 0.5s;
			&.on {
				opacity: 1;
				transform: translateY(0px);
			}
		}
		.parle{
			display: flex;
			position: relative;
			margin-bottom: 60px;
			align-items: flex-start;
			justify-content: space-between;
			background-color: #f8f8f8;
			transform: translateY(50px);
			opacity: 0;
			transition: transform 0.5s, opacity 0.5s;
			&.on {
				opacity: 1;
				transform: translateY(0px);
			}
			@include tablet{
				flex-direction: column;
				padding: 0 $gap;
			}
			@include mobileL{
				padding: 0 $gap_mob;
			}
			&:before{
				display: block;
				content: '';
				position: absolute;
				@include cols(1.5);
				height: auto;
				background-color: #fff;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				@include tablet{
					display: none;
				}
			}
			img{
				position: relative;
				transform: translate(-100px);
				opacity: 0;
				transition: transform 0.5s 0.25s, opacity 0.5s 0.25s;
				&.on {
					opacity: 1;
					transform: translate(0px);
				}
				@include cols(3);
				top: -20px;
				margin-right: 20px;
				@include tablet{
					float: none;
					width: 70%;
					max-width: none;
					margin: auto;
				}
				@include mobileL{
					width: 75%;
				}
			}
			div{
				transform: translateY(50px);
				opacity: 0;
				transition: transform 0.5s, opacity 0.5s;
				&.on {
					opacity: 1;
					transform: translateY(0px);
				}
				@include cols(6);
				padding-right: $gap;
				box-sizing: border-box;
				@include tablet{
					width: auto;
					max-width: none;
				}
			}
			h3{
				padding-top: $gap;
				margin-bottom: $gap;
				font-size: 2.1em;
				font-weight: 400;
				@include tablet{
					padding-top: 0;
					margin-bottom: $gap/2;
					font-size: 1.8em;
				}
			}
			p{
				margin-bottom: 30px;
			}
			.link{
				display: inline-block;
				position: relative;
				margin-bottom: 40px;
				color: $orange;
				font-size: .8em;
				font-weight: bold;
				letter-spacing: .1em;
				text-transform: uppercase;
				&:after{
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					bottom: -10px;
					height: 1px;
					background: $orange;
					transition: .2s;
					transform-origin: 100% 0;
				}
				&:hover{
					&:after{
						transform: translateX(10px) scale(.2, 2);
					}
				}
			}
		}
		.viewArticles{
			@include mobileL{
				display: none;
			}
		}
	}
}
