/*--------------------------------------------------------------
TITLE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#details, #onparle, #tmg{
	display: grid;
	padding-top: 0;
	grid-template-columns: auto $pageMargin cols(3) $pageMargin auto;
	grid-column-gap: $gap*2;
    grid-row-gap: $gap*2;
	grid-template-areas:
        ". . cons cons  as . ."
	;
    background-color: $bg;
	section{
		grid-template-columns: 1fr;
		grid-template-areas:
		"retour"
		"title"
		"img"
		"content";
	}
	@include laptop{
		display: grid;
	}
	@include tablet{
		display: flex;
	}
	@include mobileL{
		display: block;
	}
}


/*--------------------------------------------------------------
-	conseils
--------------------------------------------------------------*/
#details,#onparle,#tmg{
	section{
		grid-area: cons;
		margin-top: 120px;
		.retour {
				div {
					margin-right: 5px;
					align-self: center;
					display: inline-block;
					position: relative;
					transform: rotate(45deg);
					width: 8px;
					height: 8px;
					border-bottom: 2px solid #e4601d;
					border-left: 2px solid #e4601d;
			}
		}
		.back{
			margin-top: 6px;
			h3{
				margin-bottom: 29px;
				font-size: 40px;
				line-height: 50px;
				font-family: kelson;
				font-weight: 400;
			}
			figure{
				position: relative;
				z-index: 1;
				img{
					max-width: 530px;
					width: 50%;
				}
			}
			p{
				span{
					font-weight: 700;
					line-height: 20px;
				}
			}
		}
		h4{
			margin-top: -30px;
			font-size: 20px;
			font-weight: 400;
			line-height: 50px;
			font-family: kelson;
			span{
				color: $orange;
				font-weight: 500;
			}
		}
		.toto{
			position: relative;
			left: 150px;
			bottom: 58px;
			width: 605px;
			background: #fff;
			padding: 70px 25px 38px 28px;
			p{
				line-height: 28px;
				span{
					font-weight: 700;
					font-size: 1.2em;
					color: $orange;
				}
			}
			.mobile{
				display: none;
			}
		}
		@include laptop{
			display: inline-block;
			.back{
				figure{
					left: 42px;
					img{
						width: 80%;
					}
				}
			}
			.toto{
				.mobile{
					display: none;
					font-family: Kelson;
					font-size: 25px;
					font-weight: 400;
				}
				left: 0;
				width: 450px;
			}
		}
		@include tablet{
			margin-left: 4px;
			display: block;
			header{
				a{
					position: relative;
					left: 15px;
				}
			}
			.back{
				h3{
					position: relative;
					left: 15px;
					font-size: 30px;
					line-height: 35px;
					@include tablet{
						font-size: 26px;
					}
				}
				figure{
					left: 70px;
					img{
						width: 70%;
					}
				}
			}
			.toto{
				left: 15px;
				width: 400px;
			}
			.aside{
				margin-top: 0;
			}
		}
		@include mobileL{
			header{
				a{
					left: 48px;
				}
			}
			h3{
				display: none;
			}
			.back{
				margin-top: 25px;
				figure{
					left: 0;
					img{
						position: relative;
						left: 70px;
					}
				}
			}
			.toto{
				left: 39px;
				width: 300px;
				.mobile{
					display: block;
					margin-bottom: 10px;
				}
			}
		}
		@include mobileM{
			.back{
				figure{
					img{
						left: 40px;
						width: 76%;
					}
				}
			}
			.toto{
				left: 15px;
				width: 282px;
			}
		}
		@include mobileS{
			.back{
				figure{
					img{
						left: 40px;
						width: 65%;
					}
				}
			}
			.toto{
				left: 4px;
				width: 249px;
			}
		}
	}
}


/*--------------------------------------------------------------
-	Aside
--------------------------------------------------------------*/
#details,#onparle, #tmg{
	.aside{
		grid-area: as;
		margin-top: 0;
		.recommandation{
			a{
				padding: 0;
			}
		}
		.h2like{
			background-color: #fff;
			line-height: 47px;
			font-size: .8em;
		}
		.contact, .chat{
			margin-top: 24px ;
			margin-bottom: 0;
			@include tablet{
				font-size: 14px;
			}
		}
		@include laptop{
			width: 315px;
			.h2like{
				margin: 0;
			}
		}
		@include tablet{
			width: 296px;
			margin-left: 22px;
		}
		@include mobileL{
			margin-top: -84px;
			width: 356px;
			margin-left: 37px;
		}
		@include mobileM{
			width: 344px;
			margin-left: 11px;
		}
		@include mobileS{
			width: 300px;
			margin-left: 3px;
		}
	}
}

/*--------------------------------------------------------------
-	Section
--------------------------------------------------------------*/
