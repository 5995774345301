/*--------------------------------------------------------------
LOT > DETAILS > GLOBAL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Tables
- Ref
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#zoom_lot{
	.details{
		@include cols(5);
		@include tablet{
			width: auto;
			max-width: none;
		}
		section{
			position: relative;
			padding: 25px $gap;
			background: #fff;
			@include tablet{
				margin-bottom: $gap;
			}
			@include mobileL{
				margin-bottom: $gap_mob;
				padding:  25px $gap_mob;
			}
		}
	}
}



/*--------------------------------------------------------------
-	Tables
--------------------------------------------------------------*/
#zoom_lot{
	.details{
		h3, .h3_like{
			margin: 30px 0 0;
			text-transform: uppercase;
			font-weight: bold;
		}
		table{
			width: 100%;
			margin-top: 20px;
			border-top: 1px solid $trait;
		}
		tr{
			border-bottom: 1px solid $trait;
			transition: .2s;
			&:hover{
				background: lighten($bg, 3);
			}
		}
		td{
			position: relative;
			vertical-align: middle;
			padding: 8px 5px;
			line-height: 1.3;
			&::first-letter{
				text-transform: uppercase;
			}
			&:first-child{
				text-transform: uppercase;
				@include mobileL{
					text-transform: none;
				}
			}
			&:last-child{
				text-align: right;
				font-weight: bold;
			}
		}
	}
}



/*--------------------------------------------------------------
-	Ref
--------------------------------------------------------------*/
#zoom_lot{
	section.reference{
		display: flex;
		justify-content: flex-end;
		padding-bottom: 50px;
		@include tablet{
			padding-bottom: 25px;
			justify-content: center;
		}
	}
}
