/*--------------------------------------------------------------
TITLE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Titre
- Img
- Content
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#testimonial{
	display: flex;
	margin-top: $gap;
	justify-content: space-between;
	@include laptop{
		display: block;
    }
    @include mobileL{
        padding: $headerH_mob $gap_mob 0;
    }
	.article_single{
		display: flex;
		flex-direction: column;
		@include cols(6);
		@include laptop{
    			width: auto;
    			max-width: none;
    			margin-bottom: 20px;
        }
	}
	.aside{
		@include cols(3);
		@include laptop{
            width: auto;
            max-width: none;
        }
	}
}



/*--------------------------------------------------------------
-	Titre
--------------------------------------------------------------*/
#testimonial{
	.article_single{

		h1{
			color: $scamac;
			font-size: 2.8em;
			@include kelson;
			@include mobileL{
				text-align: center;
				margin-top: 15px;
				font-size: 1.8em;
			}
			.small{
				display: block;
				margin-top: 15px;
				font-size: .5em;
				@include mobileL{
					margin-top: 10px;
				}
			}
			em{
				color: $orange;
				font-weight: 500;
			}
		}
	}
}



/*--------------------------------------------------------------
-	Img
--------------------------------------------------------------*/
#testimonial{
	.article_single{
		img{
			display: block;
			position: relative;
			margin-top: 20px;
			@include cols(4);
			z-index: 1;
			@include laptop{
	            @include cols(6);
	        }
	        @include mobileL{
	            margin: auto;
	            margin-top: 15px;
	            width: 80%;
	            max-width: none;
	        }
		}
	}
}



/*--------------------------------------------------------------
-	Content
--------------------------------------------------------------*/
#testimonial{
	.article_single{
		.content{
			position: relative;
			@include cols(5);
			margin-top: -60px;
			padding: 80px $gap 40px $gap*2;
			align-self: flex-end;
			background: #fff;
			box-sizing: border-box;
			@include laptop{
	            @include cols(8);
	        }
			@include mobileL{
	            @include cols(9);
				width: 100%;
				align-self: center;
				padding: 80px $gap_mob 40px $gap_mob;
	        }
			&::before{
				content: '«';
				position: absolute;
				top: 46px;
				left: -100px;
				color: $orange;
				font-size: 170px;
				font-weight: bold;
				line-height: 0;
				z-index: 2;
				@include tablet{
					left: -75px;
					font-size: 120px;
				}
				@include mobileL{
					display: none;
				};
			}
		}
		p{
			margin-top: 20px;
		}
		dt{
			color: $orange;
			font-weight: bold;
			font-size: 1.2em;
			&+dd p:first-child{
				margin-top: 0;
			}
		}
		dd{
			margin-bottom: 30px;
		}
		ul{
			list-style-type: disc;
			margin-left: 15px;
		}
		ol{
			list-style-type: decimal;
			margin-left: 15px;
		}
		li{
			margin: 5px 0;
		}
	}
}
