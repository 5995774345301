/*--------------------------------------------------------------
HOME -> HERO  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Search Module
- Slider
- Global
- Txt
- Img
- Slider nav
- Scroll
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#hero{
	display: flex;
	position: relative;
	align-items: flex-start;
	justify-content: flex-end;
	padding: 0 $boxing;
	min-height: calc(100vh - #{$headerH});
	color: #fff;
	background: #000;
	overflow: hidden;
	box-sizing: border-box;
	background-clip: border-box;
	@include laptop{
		padding: 0 $gap;
		align-items: flex-end;
	}
	@include mobileL{
		padding: 0 $gap_mob;
	}
}
/*--------------------------------------------------------------
-	contact mobile
--------------------------------------------------------------*/
#hero{
	.boutton-mobile-contact {
		position: absolute;
		top: 0;
		padding: $gap*0.5;
		border-radius: 50%;
		z-index: 2;
		right: 0;
		background: $orange;
		stroke: white;
		margin: $gap;
		display: none;
		@include laptop{
			display: block;
		}
		@include mobileL{
			margin: $gap_mob;
			padding: $gap_mob*0.5;
		}
	}
}

/*--------------------------------------------------------------
-	Search Module
--------------------------------------------------------------*/
#hero{
	.search{
		position: relative;
		@include cols(4);
		margin: 100px $gap 0;
		z-index: 1;
		@include smallH{
			margin-top: 40px;
		}
		@include laptop{
			flex: 1 1;
			max-width: none;
			width: auto;
			margin: 230px auto 90px;
		}
		@media (min-height: 1250px) and (max-width: 1024px) {
		  margin: 230px auto 350px;
	   }
		@include mobileL{
			margin-top: 160px;
			margin: 230px auto 30px;
		}
		.ref:focus{
			background-color: blue;
		}
	}
	.traits{
		display: block;
		position: absolute;
		width: 50px;
		height: 75px;
		right: -50px;
		@include laptopL{
			display: none;
		}
		&::before, &::after{
			content: '';
			position: absolute;
			background: #ddd;
			transform-origin: 0 100%;
			animation: scaleY .3s .7s backwards;
		}
		&::before{
			top: 0;
			bottom: 10px;
			width: 1px;
		}
		&::after{
			right: 0;
			bottom: 0;
			left: 10px;
			height: 1px;
			animation-name: scaleX;
			animation-delay: .8s;
		}
		&:first-child{
			top: -75px;
		}
		&:nth-child(2){
			bottom: -75px;
			transform: scaleY(-1);
		}
	}
}



/*--------------------------------------------------------------
-	Slider
--------------------------------------------------------------*/
#hero{
	@include mobileL{
		background-color: #efefef;
	}


	/*--------------------------------------------------------------
	-	Global
	--------------------------------------------------------------*/
	$sliderDuration: 1.5s;
	section{
		@include abs;
		padding: 0 $boxing;
		font-size: 1.3em;
		@include mobileL{
			height: 350px;
		}
		&.on{
			z-index: 1;
		}
	}



	/*--------------------------------------------------------------
	-	Txt
	--------------------------------------------------------------*/
	.txt{
		position: relative;
		max-width: 470px;
		margin-left: $gap;
		padding-left: 25px;
		z-index: 1;
		@include laptop{
			margin: 0 $gap;
			padding: 0;
		}
		@include mobileL{
			margin: 0 $gap_mob;
		}
		&::before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 1px;
			background: #fff;
			transform: scaleY(0);
			transform-origin: 0 0;
			transition: transform .5s $quint;
			animation: scaleY .5s .2s backwards;
			@include laptop{
				display: none;
			}
		}
	}
	h1, .txt p, .tel, h2{
		opacity: 0;
		visibility: hidden;
		transition: .3s;
		transform: translateY(-20px);
		animation: slideIn .5s .2s backwards;
	}
	h1, h2{
		margin: 40px 0;
		font-family: kelson;
		font-size: 3.3em;
		font-weight: 300;
		transition-delay: .2s;
		@include laptop{
			margin-top: $gap;
			font-size: 2.5em;
		}
		@include smallH{
			font-size: 2.3em;
		}
		@include mobileL{
			display: none;
			font-size: 8vw;
		}
		strong{
			font-weight: 500;
		}
	}
	.titre-mobile-index {
		display: none;
		max-width: 200px;
		font-family: Kelson;
		font-size: 25px;
		font-weight: 500;
		line-height: 30px;
		@include mobileL{
			display: block;
		}
	}
	.txt p{
		animation-delay: .3s;
		transition-delay: .1s;
		@include laptop{
			display: none;
		}
	}
	.tel{
		position: relative;
		margin: 30px $gap 0;
		@include button($orange);
		padding: 0 $gap;
		font-size: 1.1em;
		line-height: 45px;
		z-index: 1;
		animation-delay: .4s;
		@include laptop{
			display: none;
		}
	}
	.dev {
		&::before{
			content: "Afficher le numéro";
			position: absolute;
			font-family: arial;
			background-color: $orange;
			z-index: 1;
 		  top: 50%;
  		transform: translateY(-50%);
			font-size: 0.65em;
			animation-delay: .4s;
			text-align: center;
			width: 78%;
			height: auto;
			line-height: auto;
			z-index: 3;
		}
	}
	.on{
		.txt{
			&::before{
				transform: none;
				transition-delay: $sliderDuration - .5s;
			}
		}
		h1, .txt p, .tel, h2{
			opacity: 1;
			visibility: visible;
			transform: none;
		}
		h1, h2{
			transition-delay: $sliderDuration - .5s + .1s;
		}
		.txt p{
			transition-delay: $sliderDuration - .5s + .2s;
		}
		.tel{
			transition-delay: $sliderDuration - .5s + .3s;
		}
	}



	/*--------------------------------------------------------------
	-	Img
	--------------------------------------------------------------*/
	figure{
		@include abs;
		overflow: hidden;
		transition: $sliderDuration $quint;
		@include mobileL{
			transition: 0s 0s;
		}
	}
	img{
		display: block;
		user-select: none;
		pointer-events: none;
		transition: $sliderDuration $quint;
		@media (min-aspect-ratio: 16/9){
			width: 100%;
		}
		@media (max-aspect-ratio: 16/9){
			height: 100%;
		}
		@supports (object-fit: cover) {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
		@include mobileL{
			transition: 0;
		}
	}
	.left{
		figure{
			opacity: 0;
			transform: translateX(-100%);
			@include mobileL{
				transform: translateX(0%);
			}
		}
		img{
			transform: translateX(50%);
			@include mobileL{
				transform: translateX(0%);
			}
		}
	}
	.right{
		figure{
			opacity: 0;
			transform: translateX(100%);
			@include mobileL{
			transform: translateX(0%);
			}
		}
		img{
			transform: translateX(-50%);
			@include mobileL{
				transform: translateX(0%);
			}
		}
	}
}


/*--------------------------------------------------------------
-	Slider nav
--------------------------------------------------------------*/
#hero .slider-nav{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 40px;
	width: 100%;
	max-width: $layoutW;
	margin: auto;
	padding: 0 $gap;
	z-index: 1;
	animation: slideIn .4s .7s backwards;
	@include laptop{
		bottom: $gap;
	}
	@media (min-height: 1250px) and (max-width: 1024px) {
	padding: 50px $gap;
 }
	@include mobileL{
		display: none;
	}
	input{
		display: none;
	}
	label{
		display: inline-block;
		position: relative;
		padding: 7px;
		cursor: pointer;
	}
	span{
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #fff;
		transition: .2s;
	}
	:hover span, :checked+span{
		background: $orange;
	}
}



/*--------------------------------------------------------------
-	Scroll
--------------------------------------------------------------*/
#hero .scroll{
	position: absolute;
	left: 0;
	right: 0;
	bottom: $gap;
	margin: auto;
	width: 200px;
	height: 40px;
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	letter-spacing: .8em;
	text-transform: uppercase;
	z-index: 1;
	animation: slideIn .4s 1s both;
	@media (min-height: 1250px) and (max-width: 1024px) {
	bottom: 70px;
 }
	@include mobileL{
		display: none;
	}
	&::before, &:after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 5px;
		margin: auto;
	}
	&::before{
		width: 1px;
		height: 15px;
		background: #fff;
		transition: .2s;
		transform-origin: 0 100%;
	}
	&:after{
		width: 8px;
		height: 8px;
		border: 1px solid #fff;
		border-top: none;
		border-left: none;
		transform: rotate(45deg);
	}
	span{
		display: block;
		padding-left: .8em;
		transition: .2s;
	}
	svg{
		opacity: -1.5;
		display: block;
		margin: 10px auto 0;
		stroke: currentColor;
	}
	&:hover{
		&::before{
			transform: scaleY(1.5);
		}
		span{
			color: #fff;
			transform: translateY(-10px);
		}
	}
}
