/*--------------------------------------------------------------
SUPERFICIES  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Total
- Table
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Total
--------------------------------------------------------------*/
#zoom_lot{
	.surface_totale{
		position: absolute;
		top: $gap;
		right: $gap;
		@include button($orange, true, false);
		font-style: italic;
		font-weight: normal;
		@include mobileL{
			position: relative;
			margin-top: 10px;
			top: 0;
			right: 0;
		}
		em{
			font-weight: bold;
			font-style: normal;
		}
	}
}



/*--------------------------------------------------------------
-	Table
--------------------------------------------------------------*/
#zoom_lot{
	.superficies{
		td{
			&:first-child{
				text-transform: none;
			}
			&:last-child{
				color: $orange;
			}
		}
	}
}
