#popup-zone {
	display: none;
	top: 0;
  left: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.3);
	z-index: 3;
	.content {
		display: block;
		height: 50vh;
		width: 60vw;
		padding: 5px;
		position: relative;
		margin: auto;
		margin-top: 200px;
		opacity: 1;
		background-color: #ffffff;
		text-align: center;
		@include laptop {
			width: 50%;
		}
		p{
			font-size: 16px;
		}
	}
	.map {
		height: 100%;
		width: 100%;
		z-index: 2;
	}
	#zone-buttons {
		position: absolute;
		top   : 0;
		bottom: 0;
		left  : 0;
		right : 0;
		z-index: 10;
		pointer-events: none;

		button {
			pointer-events: auto;
			position: absolute;
			height: 30px;
			width: 30px;
			background-color: $bleu;
			border-radius: 4px;

			transition : {
				duration : 100ms;
				property : background-color;
			};

			svg {
				height: 100%;
				width: 100%;
				fill: none;
				stroke : white;
				transition : {
					duration : 100ms;
					property : stroke;
				};
			}

			&.activated {
				background-color: white;
				border: 1px solid $bleu;
				svg {
					stroke : $bleu;
				}
			}
		}
		#zone-pen, #zone-trash, #zone-submit {
			bottom: 20px;
		}
		#zone-pen {
			right: 150px;
			padding: 5px;
		}
		#zone-trash {
			right: 115px;
			padding: 7px;
		}
		#zone-submit {
			right: 10px;
			width: 100px;
			text-transform: uppercase;
			color: white;
			font-size: 12px;
		}
		#zone-zoom-more, #zone-zoom-less {
			color: white;
			right: 10px;
		}
		#zone-zoom-more {
			top: 10px;
		}
		#zone-zoom-less {
			top: 45px;
		}
	}
}
