/*--------------------------------------------------------------
HEADER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Back
- Title
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
.header_lot{
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include mobileL{
		flex-direction: column;
	}
	&::after{
		content: '';
		width: 90px;
		@include mobileL{
			display: none;
		}
	}
}



/*--------------------------------------------------------------
-	Back
--------------------------------------------------------------*/
.header_lot{
	.back{
		flex: 0 0 60px;
		align-self: flex-end;
		@include mobileL{
			flex: auto;
			align-self: flex-start;
			padding-left: 2px;
			background: none;
		}
	}
}



/*--------------------------------------------------------------
-	Title
--------------------------------------------------------------*/
.header_lot{
	h1{
		padding: 30px $gap;
		font-size: 25px;
		text-align: center;
		@include laptopL{
			font-size: 1.8vw;
		}
		@include laptop{
			font-size: 2vw;
		}
		@include tablet{
			font-size: 18px;
		}
		@include mobileL{
			padding: $gap 0;
		}
		.orange{
			color: $orange;
			text-transform: uppercase;
		}
		strong{
			display: inline-block;
			font-weight: bold;
			&::first-letter{
				text-transform: uppercase;
			}
		}
	}
}
