/*--------------------------------------------------------------
NOSCRIPT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#noscript{
	@include fix;
	color: #6e6e6e;
	font-family: arial;
	background: #e6e6e6;
	z-index: 10000;
	div{
		@include abs(1);
		width: 400px;
		height: 210px;
	}
	h1{
		font-size: 30px;
		font-weight: bold;
		text-transform: uppercase;
	}
	p{
		font-size: 16px;
		margin: 30px 0;
	}
	strong{
		font-weight: bold;
	}
	a{
		display: inline-block;
		padding: 10px 15px;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		border: 1px solid #6e6e6e;
		border-radius: 50px;
	}
}
