/*--------------------------------------------------------------
NOS CONSEILS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
-recherche
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#home, #contact {
	.conseil {
		padding-bottom: $gap*2;
		padding-right: $gap;
		@include cols(6);
		display: flex;
		flex-direction: row;
		background: $calque;
		box-shadow: -1000px 0 0 $calque, -250px 0 0 $calque;
		flex-wrap: wrap;
		@include tablet{
			display: flex;
			@include cols(9);
			width: auto;
			padding-right: 0;
			box-shadow: -1000px 0 0 $calque, -250px 0 0 $calque ,1000px 0 0 $calque, 250px 0 0 $calque;
		}
		figure {
			box-sizing: border-box;
			top: -30px;
			z-index: 2;
			position: relative;
			@include cols(3);
			@include tablet{
				@include cols(4.5);
			}
			@include mobileL{
				top: 2px;
				width: 100%;
				max-width: none;
				margin: auto;
			}
		}
		img {
			display: block;
			width: 100%;
		}
		h2, .h2_like {
			flex-basis: 50%;
			text-align: center;
			padding-left: 50%;
			@include mobileL{
				flex-basis: 100%;
				padding-left: 0;
				text-align: center;
			}
		}
		.wrapper-texte-nos-conseils {
			position: relative;
			box-sizing: border-box;
			flex-direction: row;
			background-color: white;
			padding: $gap;
			@include cols(3);
			@include tablet{
				@include cols(4.5);
			}
			@include mobileL{
				flex-basis: 100%;
			}
			&:before{
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				width: 200px;
				height: 100%;
				background-color: white;
				right: 100%;
				@include mobileL{
					display: none;
				}
			}
			h3, .h3_like {
				position: relative;
				@include cols(3);
				color: #292d27;
				align-self: flex-end;
				font-family: Kelson;
				font-size: 30px;
				font-weight: 400;
				margin-bottom: $gap;
				width: 100%;
				@include tablet{
					@include cols(4);
				}
				@include tablet{
					font-size: 25px;
				}
				@include mobileL{
					width: auto;
				}
			}
			p {
				@include cols(3);
				position: relative;
				font-family: Arial;
				align-self: flex-end;
				font-size: 14px;
				font-weight: 400;
				width: 100%;
				margin-bottom: $gap*2;
				@include tablet{
					@include cols(4);
				}
				@include mobileL{
					width: auto;
				}
			}
		}
		.link {
			position: relative;
			float: left;
			z-index: 2;
			right: 200px;
			color: $orange;
			font-size: .8em;
			font-weight: bold;
			letter-spacing: .1em;
			text-transform: uppercase;
			@include tablet{
				margin: $gap;
			}
			@include mobileL{
				right: 0px;
				margin: $gap_mob;
			}
			&:after{
				@include pseudo;
				margin-top: 6px;
				height: 1px;
				background: $orange;
				transition: .2s;
				transform-origin: 100% 0;
			}
			&:hover{
				&:after{
					transform: translateX(10px) scale(.2, 2);
				}
			}
		}
	}
}



/*--------------------------------------------------------------
-	recherche
--------------------------------------------------------------*/
#recherche{
	.wrapper-nos-conseils {
		display: flex;
		position: relative;
		@include laptop{
			flex-direction: column;
		}
		.conseil {
			justify-content: space-between;
			padding-bottom: $gap*2;
			padding-right: $gap;
			@include cols(6);
			display: flex;
			flex-direction: row;
			background: $calque;
			box-shadow: -1000px 0 0 $calque, -250px 0 0 $calque;
			flex-wrap: wrap;
			@include laptop{
				display: flex;
				@include cols(9);
				width: auto;
				padding-right: 0;
				box-shadow: -1000px 0 0 $calque, -250px 0 0 $calque ,1000px 0 0 $calque, 250px 0 0 $calque;
			}
			figure {
				position: relative;
				box-sizing: border-box;
				top: -30px;
				z-index: 2;
				@include cols(3);
				@include laptop{
					@include cols(4.5);
				}
				@include mobileL{
					margin: auto;
					top: 2px;
					width: 100%;
					max-width: none;
				}
			}
			img {
				width: 100%;
			}
			h2, .h2_like {
				@include cols(5);
				text-align: right;
				@include laptop{
					@include cols(7.5);
					text-align: right;
				}
				@include tablet{
					@include cols(8.5);
				}
				@include mobileL{
					@include cols(9);
					text-align: center;
				}
			}
			.wrapper-texte-nos-conseils {
				position: relative;
				@include cols(3);
				padding: $gap;
				box-sizing: border-box;
				flex-direction: row;
				background-color: white;
				@include laptop{
					@include cols(4.5);
				}
				@include mobileL{
					padding: $gap_mob;
					flex-basis: 100%;
				}
				&:before{
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					width: 200px;
					height: 100%;
					background-color: white;
					right: 100%;
					@include mobileL{
						display: none;
					}
				}
				h3, .h3_like {
					position: relative;
					@include cols(3);
					color: #292d27;
					align-self: flex-end;
					font-family: Kelson;
					font-size: 30px;
					font-weight: 400;
					margin-bottom: $gap;
					width: 100%;
					@include laptop{
						@include cols(4);
					}
					@include tablet{
						font-size: 25px;
					}
					@include mobileL{
						width: auto;
					}
				}
				p {
					position: relative;
					@include cols(3);
					font-family: Arial;
					align-self: flex-end;
					font-size: 14px;
					font-weight: 400;
					width: 100%;
					margin-bottom: $gap*2;
					@include laptop{
						@include cols(4);
					}
					@include mobileL{
						width: auto;
					}
				}
			}
			.link {
				position: relative;
				float: left;
				z-index: 2;
				right: 200px;
				color: $orange;
				font-size: .8em;
				font-weight: bold;
				letter-spacing: .1em;
				text-transform: uppercase;
				margin: $gap;
				@include mobileL{
					right: 0px;
					margin: $gap_mob;
				}
				&:after{
					@include pseudo;
					margin-top: 6px;
					height: 1px;
					background: $orange;
					transition: .2s;
					transform-origin: 100% 0;
				}
				&:hover{
					&:after{
						transform: translateX(10px) scale(.2, 2);
					}
				}
			}
		}
		.wrapper-contact-chat {
			display: inline-block;
			position: relative;
			@include cols(3);
			margin: 0 0 $gap $gap;
			@include laptop{
				@include cols(8);
				position: relative;
				display: block;
				width: 100%;
				margin: $gap 0 0 0;
				top: 0%;
				transform: translateY(0%);
			}
			.chat, .contact {
				margin: 0 0 $gap 0;
			}
		}
	}
}
